export enum QuizStatus {
  Draft = 'draft',
  Reviewed = 'reviewed',
  Sent = 'sent',
  Approved = 'approved',
}

export interface QuizReviewer {
  id: string
  email: string
  reviewedAt?: string
  // for matching latest quiz version
  version?: number
  updatedAt: string
}

export interface QuizComment {
  text: string
  approved: boolean
  reviewer: QuizReviewer
  createdAt: string
  uodatedAt: string
  version: number
}

export interface QuizQuestionRecord {
  id: string
  text: string
  learningOutcome: string
  answers: string[]
  version: number
  archTopicId: string
  archTopic: {
    id: string
    name: string
    link: string
    document: QuizQuestionDocument
  }
}

export interface QuizQuestionFile {
  id: string
  url: string
  type: string
  name: string
  size: number
  createdAt: string
}

export interface QuizQuestionDocument {
  id: string
  name: string
  topic: string
}

export interface QuizQuestion {
  id: string
  text?: string
  learningOutcome?: string
  answers?: string[]
  comments?: QuizComment[]
  previous?: QuizQuestionRecord
  edited: boolean
  files?: QuizQuestionFile[]
  position: number
  archTopicId?: string
  archTopic?: {
    id: string
    name: string
    link: string
    document: QuizQuestionDocument
  }
}

export interface Quiz {
  id: string
  name: string
  filename: string
  fileUrl: string
  description: string
  createdAt: string
  updatedAt: string
  sentAt: string
  deletedAt: string | null
  deleted: boolean
  status: string
  uploadedAuth0Id: string
  uploadedUserName: string
  updatedByAuth0Id: string
  updatedByUserName: string
  questions: QuizQuestion[]
  reviewers: QuizReviewer[]
  version: number
  approved: boolean
  positions: string[]
  documentIds: string[]
  // for arch tool integration
  courseId: string
}

export interface QuizCreator {
  uploadedAuth0Id: string
  uploadedUserName: string
  uploadedFirstName: string
  uploadedEmail: string
}

export interface QuizEditor {
  updatedByAuth0Id: string
  updatedByUserName: string
  updatedByFirstName: string
  updatedByEmail: string
}

export interface GeneratedQuestion {
  questionText: string
  learningOutcome: string
  correctAnswer: string
  incorrectAnswer1: string
  incorrectAnswer2: string
  incorrectAnswer3: string
  incorrectFeedback: string
  documentId?: string
  archTopicId?: string
  archTopic?: {
    id: string
    name: string
    link: string
    document: QuizQuestionDocument
  }
}
