/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  ArchClient,
  ArchCourseForTable,
} from 'apis/entities/architecture.entity'
import sortSvg from 'images/sort.svg'
import sort2Svg from 'images/sort2.svg'
import viewDetailsSvg from 'images/view-details.svg'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Tooltip } from 'react-tooltip'
import Spinner from './Spinner'
import Emitter, { Events } from 'core/emitter'
import excelSvg from 'images/excel.svg'
import {
  Direction,
  DirectionAsc,
  DirectionDesc,
  alternateStyle,
  tooltipStyle,
  tooltipStyleReverse,
} from 'utils/TableUtils'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import DropDownMenuForTable, { DropDownItem } from './DropDownMenuForTable'
import { getExportFileNameForCourse } from 'utils/ArchUtil'

enum SortBy {
  Client = 'client',
  Program = 'program',
  Intake = 'intake',
  Course = 'course',
}

interface ArchReadOnlyViewProps {
  courses: ArchCourseForTable[]
  allCourses: ArchCourseForTable[]
  isLoading: boolean
  filteredProgramName?: string
  filteredIntakeName?: string
}

interface ClientDict {
  [key: string]: {
    programId: string | null
    intakeId: string | null
    courseId: string | null
  }
}

export default function ArchReadOnlyView({
  courses,
  allCourses,
  isLoading,
  filteredProgramName,
  filteredIntakeName,
}: ArchReadOnlyViewProps) {
  const { exportArchExcel } = useArchitectureApi()
  const [sortConfig, setSortConfig] = useState<{
    key: SortBy
    direction: Direction
  } | null>({ key: SortBy.Client, direction: DirectionAsc })
  const [orderByClient, setOrderByClient] = useState<Direction>('')
  const [orderByProgram, setOrderByProgram] = useState<Direction>('')
  const [orderByIntake, setOrderByIntake] = useState<Direction>('')
  const [orderByCourse, setOrderByCourse] = useState<Direction>('')
  const [selectedClientDict, setSelectedClientDict] = useState<ClientDict>({})

  const sortedCourses = useMemo(() => {
    if (!sortConfig) {
      return courses
    }

    // filter courses by program and intake if any
    let tmpCourses = [...courses]
    if (filteredProgramName) {
      tmpCourses = tmpCourses.filter(
        (c) => c.programName === filteredProgramName,
      )
    }
    // console.log('filteredIntakeName:', filteredIntakeName)
    if (filteredIntakeName) {
      tmpCourses = tmpCourses.filter((c) => c.intakeName === filteredIntakeName)
    }

    const sortedCourses = [...tmpCourses]
    switch (sortConfig.key) {
      case SortBy.Client:
        sortedCourses.sort((a, b) => {
          if (a.clientName < b.clientName) {
            return sortConfig.direction === DirectionAsc ? -1 : 1
          }
          if (a.clientName > b.clientName) {
            return sortConfig.direction === DirectionAsc ? 1 : -1
          }
          return 0
        })
        break
      case SortBy.Program:
        sortedCourses.sort((a, b) => {
          if (a.programName < b.programName) {
            return sortConfig.direction === DirectionAsc ? -1 : 1
          }
          if (a.programName > b.programName) {
            return sortConfig.direction === DirectionAsc ? 1 : -1
          }
          return 0
        })
        break
      case SortBy.Intake:
        sortedCourses.sort((a, b) => {
          if (a.intakeName < b.intakeName) {
            return sortConfig.direction === DirectionAsc ? -1 : 1
          }
          if (a.intakeName > b.intakeName) {
            return sortConfig.direction === DirectionAsc ? 1 : -1
          }
          return 0
        })
        break
      case SortBy.Course:
        sortedCourses.sort((a, b) => {
          if (a.name < b.name) {
            return sortConfig.direction === DirectionAsc ? -1 : 1
          }
          if (a.name > b.name) {
            return sortConfig.direction === DirectionAsc ? 1 : -1
          }
          return 0
        })
        break
      default:
        break
    }

    if (sortConfig.key !== SortBy.Course) {
      // sort by createdAt if client name is the same
      sortedCourses.sort((a, b) => {
        if (a.clientName === b.clientName) {
          // date comparison
          const dateA = new Date(a.createdAt).getTime()
          const dateB = new Date(b.createdAt).getTime()
          return dateB - dateA
        }
        return 0
      })
    }

    return sortedCourses
  }, [courses, filteredIntakeName, filteredProgramName, sortConfig])

  const onClickSort = (key: SortBy) => {
    let ascending = true
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === DirectionAsc
    ) {
      ascending = false
    }

    switch (key) {
      case SortBy.Client:
        setOrderByClient(ascending ? DirectionAsc : DirectionDesc)
        break
      case SortBy.Program:
        setOrderByProgram(ascending ? DirectionAsc : DirectionDesc)
        break
      case SortBy.Intake:
        setOrderByIntake(ascending ? DirectionAsc : DirectionDesc)
        break
      case SortBy.Course:
        setOrderByCourse(ascending ? DirectionAsc : DirectionDesc)
        break
      default:
        break
    }

    setSortConfig({ key, direction: ascending ? DirectionAsc : DirectionDesc })
  }

  const getTooptipStyle = (key: SortBy): any => {
    switch (key) {
      case SortBy.Client:
        return orderByClient === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.Program:
        return orderByProgram === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.Intake:
        return orderByIntake === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.Course:
        return orderByCourse === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle

      default:
        return tooltipStyle
    }
  }

  const getSortingText = (key: SortBy): string => {
    switch (key) {
      case SortBy.Client:
        if (orderByClient === DirectionAsc) return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.Program:
        if (orderByProgram === DirectionAsc) return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.Intake:
        if (orderByIntake === DirectionAsc) return 'Sort Z to A'
        return 'Sort A to Z'
      case SortBy.Course:
        if (orderByCourse === DirectionAsc) return 'Sort Z to A'
        return 'Sort A to Z'
      default:
        return ''
    }
  }

  const onClickViewDetails = (clientId: string, courseId: string) => {
    courseId = selectedClientDict[clientId]?.courseId || courseId
    const tmpCourse = allCourses.find((c) => c.id === courseId)
    Emitter.emit(Events.ShowArchCourseDetails, { course: tmpCourse })
  }

  const onClickExport = async (
    clientId: string,
    programId: string,
    intakeId: string,
    courseId: string,
  ) => {
    try {
      programId = selectedClientDict[clientId]?.programId || programId
      intakeId = selectedClientDict[clientId]?.intakeId || intakeId
      courseId = selectedClientDict[clientId]?.courseId || courseId
      // console.log('Exporting excel for course:', programId, intakeId, courseId)
      const course = allCourses.find((c) => c.id === courseId)
      if (!course) {
        console.log('Course not found:', courseId)
        return
      }
      const filename = getExportFileNameForCourse(course.name)
      const res = await exportArchExcel(programId, intakeId, courseId)
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.log('Error exporting excel', error)
    }
  }

  const getRelatedProgramsByClientId = (clientId: string): DropDownItem[] => {
    return (
      allCourses
        .filter((c) => c.clientId === clientId)
        .map((c) => ({
          id: c.programId,
          name: c.programName,
          value: c.programId,
        }))
        // remove duplicates
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id),
        )
    )
  }

  const getRelatedIntakesByProgramId = (programId: string): DropDownItem[] => {
    return (
      allCourses
        .filter((c) => c.programId === programId)
        .map((c) => ({
          id: c.intakeId,
          name: c.intakeName,
          value: c.intakeId,
        }))
        // remove duplicates
        .filter(
          (item, index, self) =>
            index === self.findIndex((t) => t.id === item.id),
        )
    )
  }

  const getRelatedCoursesByIntakeId = (intakeId: string): DropDownItem[] => {
    return allCourses
      .filter((c) => c.intakeId === intakeId)
      .map((c) => ({
        id: c.id,
        name: c.name,
        value: c.id,
      }))
  }

  const renderRow = (course: ArchCourseForTable, index: number) => {
    return (
      <tr key={index}>
        <td className={`${alternateStyle('td', true)}`}>
          <div className="flex items-center justify-center px-[16px]">
            {course.clientName}
          </div>
        </td>
        <td className={alternateStyle('td')}>
          <div className="flex items-center justify-center px-[16px]">
            {/* <DropDownMenuForTable
              items={getRelatedProgramsByClientId(course.clientId)}
              onSelected={(selected) => {
                const firstIntake = getRelatedIntakesByProgramId(selected.id)[0]
                const firstCourse = getRelatedCoursesByIntakeId(
                  firstIntake.id,
                )[0]
                const tmpDict = { ...selectedClientDict }
                tmpDict[course.clientId] = {
                  programId: selected.id,
                  intakeId: firstIntake.id,
                  courseId: firstCourse.id,
                }
                setSelectedClientDict(tmpDict)
              }}
              itemStyle={{ fontSize: '14px' }}
            /> */}
            {course.programName}
          </div>
        </td>
        <td className={alternateStyle('td')}>
          <div className="flex items-center justify-center px-[16px]">
            {/* <DropDownMenuForTable
              items={getRelatedIntakesByProgramId(
                selectedClientDict[course.clientId]?.programId ||
                  course.programId,
              )}
              onSelected={(selected) => {
                const firstCourse = getRelatedCoursesByIntakeId(selected.id)[0]
                const tmpDict = { ...selectedClientDict }
                tmpDict[course.clientId] = {
                  ...tmpDict[course.clientId],
                  intakeId: selected.id,
                  courseId: firstCourse.id,
                }
                setSelectedClientDict(tmpDict)
              }}
              itemStyle={{ fontSize: '14px' }}
            /> */}
            {course.intakeName}
          </div>
        </td>
        <td className={alternateStyle('td')}>
          <div className="flex items-center justify-center gap-1 px-[16px]">
            {/* <DropDownMenuForTable
              items={getRelatedCoursesByIntakeId(
                selectedClientDict[course.clientId]?.intakeId ||
                  course.intakeId,
              )}
              onSelected={(selected) => {
                const tmpDict = { ...selectedClientDict }
                tmpDict[course.clientId] = {
                  ...tmpDict[course.clientId],
                  courseId: selected.id,
                }
                setSelectedClientDict(tmpDict)
              }}
              itemStyle={{ fontSize: '12px' }}
            /> */}
            <div className="grow">{course.name}</div>
            <div className="w-[16px]" />
            <div
              className="cursor-pointer min-w-[17px]"
              data-tooltip-id="tooltip-view-details"
              onClick={() => onClickViewDetails(course.clientId, course.id)}
            >
              <img src={viewDetailsSvg} alt="view details" />
            </div>
          </div>
        </td>
        <td className={alternateStyle('td')}>
          <div
            className="flex items-center justify-center cursor-pointer"
            onClick={() =>
              onClickExport(
                course.clientId,
                course.programId,
                course.intakeId,
                course.id,
              )
            }
          >
            <img src={excelSvg} alt="excel" />
          </div>
        </td>
      </tr>
    )
  }

  const onRenamedArchClient = useCallback(
    (data: { client: ArchClient }) => {
      const clientId = data.client.id
      const clientName = data.client.name
      // update client name in all courses
      const tmpCourses = [...allCourses]
      tmpCourses.forEach((c) => {
        if (c.clientId === clientId) {
          c.clientName = clientName
        }
      })
    },
    [allCourses],
  )

  useEffect(() => {
    Emitter.on(Events.RenamedArchClient, onRenamedArchClient)
    return () => {
      Emitter.off(Events.RenamedArchClient, onRenamedArchClient)
    }
  }, [onRenamedArchClient])

  return (
    <div className="flex flex-col">
      {isLoading && <Spinner />}
      {!isLoading && sortedCourses.length === 0 && (
        <div className="flex-1 flex items-start justify-center">
          No data available
        </div>
      )}
      {!isLoading && sortedCourses.length > 0 && (
        <>
          <div className="overflow-y-auto mentem-scrollbar-2 max-h-[60vh]">
            <table className="mentem-table mentem-table-highlight">
              <thead>
                <tr>
                  <th
                    className={`${alternateStyle('th', true)} h-[55px] w-[20%]`}
                    style={{
                      zIndex: 2,
                    }}
                  >
                    <div className="flex flex-row items-center">
                      <span className="grow">Client</span>
                      <img
                        className="cursor-pointer"
                        src={sortSvg}
                        alt="sort"
                        onClick={() => onClickSort(SortBy.Client)}
                        data-tooltip-id="tooltip-client"
                      />
                    </div>
                  </th>
                  <th className={`${alternateStyle('th')} w-[30%]`}>
                    <div className="flex flex-row items-center">
                      <span className="grow">Program</span>
                      <img
                        className="cursor-pointer"
                        src={sort2Svg}
                        alt="sort"
                        onClick={() => onClickSort(SortBy.Program)}
                        data-tooltip-id="tooltip-program"
                      />
                    </div>
                  </th>
                  <th className={`${alternateStyle('th')} w-[20%]`}>
                    <div className="flex flex-row items-center">
                      <span className="grow">Intake</span>
                      <img
                        className="cursor-pointer"
                        src={sortSvg}
                        alt="sort"
                        onClick={() => onClickSort(SortBy.Intake)}
                        data-tooltip-id="tooltip-intake"
                      />
                    </div>
                  </th>
                  <th className={`${alternateStyle('th')} w-[30%]`}>
                    <div className="flex flex-row items-center">
                      <span className="grow">Course</span>
                      <img
                        className="cursor-pointer"
                        src={sort2Svg}
                        alt="sort"
                        onClick={() => onClickSort(SortBy.Course)}
                        data-tooltip-id="tooltip-course"
                      />
                    </div>
                  </th>
                  <th className={`${alternateStyle('th')} w-[30%]`}>
                    <div className="flex flex-row items-center">
                      <span className="grow">Export</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {sortedCourses.map(
                  (course: ArchCourseForTable, index: number) => {
                    return renderRow(course, index)
                  },
                )}
              </tbody>
            </table>
            <Tooltip
              id="tooltip-view-details"
              className="mentem-tooltip"
              style={tooltipStyle}
              place="top"
              noArrow={true}
            >
              View details
            </Tooltip>
            <Tooltip
              id="tooltip-client"
              className="mentem-tooltip"
              style={getTooptipStyle(SortBy.Client)}
              place="top"
              noArrow={true}
            >
              {getSortingText(SortBy.Client)}
            </Tooltip>
            <Tooltip
              id="tooltip-program"
              className="mentem-tooltip"
              style={getTooptipStyle(SortBy.Program)}
              place="top"
              noArrow={true}
            >
              {getSortingText(SortBy.Program)}
            </Tooltip>
            <Tooltip
              id="tooltip-intake"
              className="mentem-tooltip"
              style={getTooptipStyle(SortBy.Intake)}
              place="top"
              noArrow={true}
            >
              {getSortingText(SortBy.Intake)}
            </Tooltip>
            <Tooltip
              id="tooltip-course"
              className="mentem-tooltip"
              style={getTooptipStyle(SortBy.Course)}
              place="top"
              noArrow={true}
            >
              {getSortingText(SortBy.Course)}
            </Tooltip>
          </div>
        </>
      )}
    </div>
  )
}
