import { TextareaAutosize } from '@mui/base'
import {
  ArchClient,
  ArchProgram,
  ArchIntake,
  ArchCourseForTable,
} from 'apis/entities/architecture.entity'
import { Quiz, QuizQuestion } from 'apis/entities/quiz.entity'
import SidebarLayout from 'components/SidebarLayout'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import { Permission, Role, useAuth } from 'providers/AuthProvider'
import { useQuizApi } from 'providers/QuizApiProvider'
import React, { useEffect, useMemo, useRef } from 'react'
import { useCallback, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import Path from 'routes/Path'
import { sortIntakeOptionsByCreatedAt } from 'utils/ArchUtil'
import { StyleUtil } from 'utils/StyleUtil'
import { ToastUtil } from 'utils/ToastUtil'
import DropDownMenu, {
  DropDownItem,
  DropDownMenuRefType,
} from 'views/DropDownMenu'
import QuizDetails, { GeneratorData } from 'views/QuizDetails'
import QuizGenerator from 'views/QuizGenerator'
import QuizUpload from 'views/QuizUpload'
import Spinner from 'views/Spinner'
import WidgetAddReviewers from 'views/WidgetAddReviewers'
import WidgetQuestionGenerator from 'views/WidgetQuestionGenerator'
import WidgetQuizGenerator, { MessageType } from 'views/WidgetQuizGenerator'

enum CreateMode {
  AutoGenerate = 'Auto generate',
  CreateDraft = 'Create draft',
  ImportFromExcel = 'Import from Excel',
}

const ClientOptionShowAll = {
  id: 'all',
  name: 'All clients',
  value: 'all',
  isLabel: false,
}

const ProgramOptionShowAll = {
  id: 'all',
  name: 'All programs',
  value: 'all',
  isLabel: false,
}

const IntakeOptionShowAll = {
  id: 'all',
  name: 'All intakes',
  value: 'all',
  isLabel: false,
}

function QuizCreatePage() {
  const navigate = useNavigate()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams()
  // get clientId, programId, intakeId from query params
  const clientId = searchParams.get('clientId') || ''
  const programId = searchParams.get('programId') || ''
  const intakeId = searchParams.get('intakeId') || ''
  const quizId = searchParams.get('quizId') || ''
  // console.log('clientId:', clientId)
  // console.log('programId:', programId)
  // console.log('intakeId:', intakeId)
  // console.log('quizId:', quizId)

  const { isLogged, getRole, permissions, getAuth0UserDetails } = useAuth()
  const [isCreating, setIsCreating] = useState(false)
  const { createQuiz, addQuizQuestion } = useQuizApi()
  const [isClickDelete, setIsClickDelete] = useState<boolean>(false)
  const refInputDescription = React.createRef<HTMLTextAreaElement>()
  const refInputName = React.createRef<HTMLTextAreaElement>()
  const [createMode, setCreateMode] = useState<CreateMode | null>(null)
  const [created, setCreated] = useState<boolean>(false)

  // architecture
  const { getArchClients, getArchForTable } = useArchitectureApi()
  const [isFetchingClients, setIsFetchingClients] = useState(false)
  const [isFetchingArchitectures, setIsFetchingArchitectures] = useState(false)
  const [clients, setClients] = useState<ArchClient[]>([])
  const refSelectedClient = useRef<ArchClient | null>(null)
  const refSelectedProgram = useRef<ArchProgram | null>(null)
  const refSelectedIntake = useRef<ArchIntake | null>(null)

  const [clientOptions, setClientOptions] = useState<any>([ClientOptionShowAll])
  const [programOptions, setProgramOptions] = useState<any>([
    ProgramOptionShowAll,
  ])
  const [intakeOptions, setIntakeOptions] = useState<any>([IntakeOptionShowAll])
  // ref for dropdown menu
  const refDropDownMenuClients = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuPrograms = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuIntakes = useRef<DropDownMenuRefType>(null)
  const refCourses = useRef<ArchCourseForTable[]>([]) // for cache
  const [courses, setCourses] = useState<ArchCourseForTable[]>([])
  const [selectedCourseId, setSelectedCourseId] = useState<string>('')
  const [selectedIntakeId, setSelectedIntakeId] = useState<string>(intakeId)
  const [selectedQuizId, setSelectedQuizId] = useState<string>(quizId)
  const [messageType, setMessageType] = useState<MessageType>(
    MessageType.Generating,
  )
  const [messageData, setMessageData] = useState<any>({})
  const [showWidgetForQuizGenerator, setShowWidgetForQuizGenerator] =
    useState<boolean>(false)
  const [showWidgetForQuestionGenerator, setShowWidgetForQuestionGenerator] =
    useState<boolean>(false)

  const [generatorData, setGeneratorData] = useState<GeneratorData>()
  const [numberOfQuestionsGenerated, setNumberOfQuestionsGenerated] =
    useState<number>(0)

  const [showAddReviewersWidget, setShowAddReviewersWidget] = useState(false)
  const [selectedQuiz, setSelectedQuiz] = useState<Quiz | undefined>(undefined)

  const isFetched = useMemo(() => {
    return !isFetchingClients && !isFetchingArchitectures
  }, [isFetchingClients, isFetchingArchitectures])

  const cleanSelectedIntake = () => {
    refSelectedIntake.current = null
    setSelectedIntakeId('')
    setSelectedCourseId('')
  }

  const updateProgramOptions = useCallback(() => {
    // set all program options by distinct program names
    let tmpProgramOptions: any[] = []

    // all clients
    if (refSelectedClient.current === null) {
      let allCourses = refCourses.current
      allCourses.forEach((course) => {
        if (
          !tmpProgramOptions.find(
            (p) => p.name === course.programName && p.id === course.programId,
          )
        ) {
          tmpProgramOptions.push({
            id: course.programId,
            name: course.programName,
            value: course.programId,
            default: course.programId === programId,
            clientId: course.clientId,
          })
        }
      })
    } else {
      // filter by selected client, use selected client's object
      const programs = refSelectedClient.current.programs
      programs.forEach((program) => {
        if (!tmpProgramOptions.find((p) => p.name === program.name)) {
          tmpProgramOptions.push({
            id: program.id,
            name: program.name,
            value: program.id,
            default: program.id === programId,
            clientId: refSelectedClient.current?.id,
          })
        }
      })
    }
    // console.log(refSelectedClient.current)
    // console.log('tmpProgramOptions', tmpProgramOptions)

    // sort program options by alphabet ascending
    tmpProgramOptions.sort((a, b) => {
      if (a.name < b.name) {
        return -1
      }
      if (a.name > b.name) {
        return 1
      }
      return 0
    })

    // filter program by selected course
    if (clientId && !refSelectedClient.current) {
      tmpProgramOptions = tmpProgramOptions.filter(
        (program) => program.clientId === clientId,
      )
    }

    setProgramOptions([ProgramOptionShowAll, ...tmpProgramOptions])
  }, [clientId, programId])

  const updateIntakeOptions = useCallback(() => {
    // set all intake options by distinct intake names
    let tmpIntakeOptions: any[] = []

    // all clients
    if (refSelectedClient.current === null) {
      let allCourses = refCourses.current
      allCourses.forEach((course) => {
        if (
          !tmpIntakeOptions.find(
            (i) => i.name === course.intakeName && i.id === course.intakeId,
          )
        ) {
          tmpIntakeOptions.push({
            id: course.intakeId,
            name: course.intakeName,
            value: course.intakeId,
            createdAt: course.intakeCreatedAt,
            default: course.intakeId === intakeId,
            programId: course.programId,
          })
        }
      })
    } else {
      // filter by selected client, use selected client's object
      const programs = refSelectedClient.current.programs
      programs.forEach((program) => {
        program.intakes.forEach((intake) => {
          if (!tmpIntakeOptions.find((i) => i.name === intake.name)) {
            tmpIntakeOptions.push({
              id: intake.id,
              name: intake.name,
              value: intake.id,
              createdAt: intake.createdAt,
              default: intake.id === intakeId,
              programId: program.id,
            })
          }
        })
      })
    }

    // filter by selected programId
    if (programId && !refSelectedClient.current) {
      tmpIntakeOptions = tmpIntakeOptions.filter(
        (intake) => intake.programId === programId,
      )
    }

    // sort intake options by date first, otherwise by alphabet ascending
    const sortedTmpIntakeOptions =
      sortIntakeOptionsByCreatedAt(tmpIntakeOptions)

    // console.log('sortedTmpIntakeOptions', sortedTmpIntakeOptions)
    setIntakeOptions([IntakeOptionShowAll, ...sortedTmpIntakeOptions])
  }, [intakeId, programId])

  const onSelectClient = (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      // label
      return
    } else if (value === 'all') {
      refSelectedClient.current = null

      // reset selected program and intake
      refSelectedProgram.current = null
      cleanSelectedIntake()

      // reset dropdowns
      refDropDownMenuPrograms.current?.setSelectedItemId(
        ProgramOptionShowAll.id,
      )
      refDropDownMenuIntakes.current?.setSelectedItemId(IntakeOptionShowAll.id)
    } else {
      // selected client
      const client = clients.find((client) => client.id === value)
      // console.log('client', client)
      if (client) {
        refSelectedClient.current = client

        // reset selected program and intake
        refSelectedProgram.current = null
        cleanSelectedIntake()
      }
    }

    updateProgramOptions()
    updateIntakeOptions()
    updateCourses()
    // reset create mode
    setCreateMode(null)
  }

  const updateCourses = () => {
    // filter courses by selected client, program, or intake
    const tmpClientId = refSelectedClient.current?.id || clientId
    const programName = refSelectedProgram.current?.name
    const intakeName = refSelectedIntake.current?.name
    // console.log('updateCourses')
    // console.log('clientId:', clientId)
    // console.log('programName:', programName)
    // console.log('intakeName:', intakeName)

    const allCourses = refCourses.current
    let tmpCourses: ArchCourseForTable[] = []
    if (tmpClientId && programName && intakeName) {
      tmpCourses = allCourses.filter(
        (course) =>
          course.clientId === tmpClientId &&
          course.programName === programName &&
          course.intakeName === intakeName,
      )
    } else if (tmpClientId && programName) {
      tmpCourses = allCourses.filter(
        (course) =>
          course.clientId === tmpClientId && course.programName === programName,
      )
    } else if (tmpClientId && intakeName) {
      tmpCourses = allCourses.filter(
        (course) =>
          course.clientId === tmpClientId && course.intakeName === intakeName,
      )
    } else if (tmpClientId) {
      // console.log('tmpClientId', tmpClientId)
      tmpCourses = allCourses.filter(
        (course) => course.clientId === tmpClientId,
      )
      // console.log('tmpCourses', tmpCourses)
    } else if (programName) {
      tmpCourses = allCourses.filter(
        (course) => course.programName === programName,
      )
    } else if (intakeName) {
      tmpCourses = allCourses.filter(
        (course) => course.intakeName === intakeName,
      )
    } else {
      tmpCourses = allCourses
    }
    // console.log('tmpCourses', tmpCourses)
    setCourses(tmpCourses)
  }

  const onSelectProgram = (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      return
    } else if (value === 'all') {
      refSelectedProgram.current = null
    } else {
      const program = { id: item.id, name: item.name } as ArchProgram
      refSelectedProgram.current = program
    }

    // reset intake options
    cleanSelectedIntake()
    // reset dropdown
    refDropDownMenuIntakes.current?.setSelectedItemId(IntakeOptionShowAll.id)

    updateCourses()
    // reset create mode
    setCreateMode(null)
  }

  const onSelectIntake = (item: DropDownItem) => {
    const value = item.value
    if (value === '0') {
      return
    } else if (value === 'all') {
      cleanSelectedIntake()
    } else {
      const intake = { id: item.id, name: item.name } as ArchIntake
      refSelectedIntake.current = intake
      setSelectedIntakeId(intake.id)
    }
    updateCourses()
    // reset create mode
    setCreateMode(null)
  }

  const onClickSaveDraft = async () => {
    if (!refInputName.current) {
      return
    }
    const name = refInputName.current.value
    const description = refInputDescription.current?.value
    if (!name) {
      ToastUtil.warning('Quiz name cannot be empty')
      return
    }

    try {
      setIsCreating(true)
      const user = getAuth0UserDetails()
      const auth0Id = user?.id
      const username = user?.username
      const firstName = user?.firstName
      const email = user?.email
      const quiz = await createQuiz(
        name,
        description,
        auth0Id,
        username,
        firstName,
        email,
        undefined,
        undefined,
        selectedCourseId,
      )
      if (quiz) {
        // add one question placeholder
        await addQuizQuestion(quiz.id, {} as QuizQuestion)

        setSelectedQuizId(quiz.id)
        // switch to edit quiz mode
        setCreated(true)
      } else {
        ToastUtil.error('Failed to create quiz')
      }
    } catch (error) {
      ToastUtil.error('Failed to create quiz')
    } finally {
      setIsCreating(false)
    }
  }

  const hasPermission = useCallback(
    (permission: string, createdAuth0Id?: string): boolean => {
      // https://horizonsfl.atlassian.net/browse/HLP-1465
      if (getRole() === Role.SuperAdmin || getRole() === Role.Admin) return true
      if (
        permissions &&
        permissions.includes(permission) &&
        getAuth0UserDetails()?.id === createdAuth0Id
      ) {
        // same user
        return true
      }
      // Author can only edit
      if (permissions && permissions.includes(permission)) {
        return true
      }
      return false
    },
    [getAuth0UserDetails, getRole, permissions],
  )

  const onClickDelete = () => {
    setIsClickDelete(true)
  }

  const onConfirmDelete = async () => {
    // navigate to quizzes page
    navigate(`${Path.quizzes.path}`)
  }

  const getActionButtonStyle = (loading: boolean) => {
    const style = StyleUtil.buttonPrimary
    if (loading) {
      return `${style} loading disabled`
    }
    return style
  }

  const disabledCourseSelection = useMemo(() => {
    return selectedQuizId !== '' && created
  }, [created, selectedQuizId])

  const fetchArchitectures = useCallback(
    async (silent: boolean = false) => {
      if (!silent) {
        setIsFetchingArchitectures(true)
      }
      try {
        const res = await getArchForTable()
        if (res && res.courses) {
          // for cache
          refCourses.current = res.courses
          // if selected intake is not empty, filter courses by intake
          setCourses(
            res.courses.filter((course) => course.intakeId === intakeId),
          )
        }
      } catch (error) {
        ToastUtil.error('Failed to fetch architectures')
        refCourses.current = []
        setCourses([])
      } finally {
        setIsFetchingArchitectures(false)
      }
    },
    [getArchForTable, intakeId],
  )

  const fetchClients = useCallback(
    async (silent: boolean = false) => {
      if (!silent) {
        setIsFetchingClients(true)
      }
      try {
        const clients = await getArchClients()
        setClients(clients)

        // Set client options
        const allOptions = clients.map((client) => ({
          id: client.id,
          name: client.name,
          value: client.id,
          default: client.id === clientId,
        }))

        // sort by alphabet
        allOptions.sort((a, b) => {
          if (a.name < b.name) {
            return -1
          }
          if (a.name > b.name) {
            return 1
          }
          return 0
        })

        setClientOptions([ClientOptionShowAll, ...allOptions])
        await fetchArchitectures(silent)
        updateProgramOptions()
        updateIntakeOptions()
      } catch (error) {
        ToastUtil.error('Failed to fetch clients')
      } finally {
        setIsFetchingClients(false)
      }
    },
    [
      clientId,
      fetchArchitectures,
      getArchClients,
      updateIntakeOptions,
      updateProgramOptions,
    ],
  )

  useEffect(() => {
    if (isLogged) {
      fetchClients()
    }
  }, [isLogged, fetchClients])

  return (
    <>
      <SidebarLayout>
        <div className={`${StyleUtil.container} flex flex-col pb-[6vh] gap-4`}>
          <div className="flex flex-row items-center pr-[5%]">
            <p className={StyleUtil.headlineSmall}>
              <span
                className="cursor-pointer hover:opacity-80"
                onClick={() => {
                  navigate(`${Path.quizzes.path}`)
                }}
              >
                Mentem quiz review tool
              </span>
              <span className="text-[#000]">
                {' '}
                / Create new {createMode ? `/ ${createMode}` : ''}
              </span>
            </p>
          </div>

          {!isFetched && <Spinner />}
          {isFetched && (
            <div className="mt-[12px] flex flex-col gap-[12px]">
              {clients.length > 0 && (
                <div className="flex flex-row items-center gap-[24px]">
                  <div className="w-[20%]">
                    <DropDownMenu
                      items={clientOptions}
                      onSelected={onSelectClient}
                      border={true}
                      ref={refDropDownMenuClients}
                      locked={created}
                    />
                  </div>
                  <div className="w-[60%]">
                    <DropDownMenu
                      items={programOptions}
                      onSelected={onSelectProgram}
                      border={true}
                      ref={refDropDownMenuPrograms}
                      locked={created}
                    />
                  </div>
                  <div className="w-[20%]">
                    <DropDownMenu
                      items={intakeOptions}
                      onSelected={onSelectIntake}
                      border={true}
                      ref={refDropDownMenuIntakes}
                      locked={created}
                    />
                  </div>
                </div>
              )}
              <div className="flex flex-wrap gap-2 w-full">
                {selectedIntakeId && courses.length === 0 && (
                  <p className="text-[#000] font-roboto font-semibold">
                    No courses found
                  </p>
                )}
                {courses.map((course, index) => (
                  <div
                    key={course.id}
                    className={`flex items-center justify-start gap-[6px] cursor-pointer ${
                      disabledCourseSelection && selectedCourseId !== course.id
                        ? 'pointer-events-none opacity-[0.4]'
                        : ''
                    }`}
                  >
                    <input
                      id={`course-${course.id}`}
                      type="radio"
                      name="courseId"
                      className="radio-primary"
                      value={course.id}
                      onChange={(e) => setSelectedCourseId(e.target.value)}
                      disabled={disabledCourseSelection}
                    />
                    <span
                      className="flex flex-row gap-[4px] font-roboto text-sm font-semibold normal-case"
                      onClick={() => {
                        const id = `course-${course.id}`
                        const radio = document.getElementById(id)
                        if (radio) {
                          radio.click()
                        }
                      }}
                    >
                      <span className="text-[#BA61FF]">Course {index + 1}</span>
                      <span className="text-[#000]">{course.name}</span>
                    </span>
                  </div>
                ))}
              </div>
              <div className="flex flex-row items-center justify-center gap-[12px]">
                <button
                  className={
                    createMode === CreateMode.AutoGenerate
                      ? StyleUtil.buttonPrimary
                      : StyleUtil.buttonSecondary
                  }
                  onClick={() => setCreateMode(CreateMode.AutoGenerate)}
                  disabled={
                    (!selectedCourseId || selectedQuizId !== '') &&
                    createMode !== CreateMode.AutoGenerate
                  }
                >
                  Auto generate
                </button>
                <button
                  className={
                    createMode === CreateMode.CreateDraft
                      ? StyleUtil.buttonPrimary
                      : StyleUtil.buttonSecondary
                  }
                  onClick={() => setCreateMode(CreateMode.CreateDraft)}
                  disabled={
                    (!selectedCourseId || selectedQuizId !== '') &&
                    createMode !== CreateMode.CreateDraft
                  }
                >
                  Create draft
                </button>
                <button
                  className={
                    createMode === CreateMode.ImportFromExcel
                      ? StyleUtil.buttonPrimary
                      : StyleUtil.buttonSecondary
                  }
                  onClick={() => setCreateMode(CreateMode.ImportFromExcel)}
                  disabled={
                    (!selectedCourseId || selectedQuizId !== '') &&
                    createMode !== CreateMode.ImportFromExcel
                  }
                >
                  Import from Excel
                </button>
              </div>
            </div>
          )}

          {createMode === CreateMode.CreateDraft && (
            <div className="quiz-create-new-container">
              <div className="flex flex-col gap-[9px] w-full">
                <div className="flex flex-row items-center w-full">
                  {hasPermission(Permission.DeleteQuizzes) && (
                    <>
                      <div className="flex items-center justify-end h-[20px]">
                        {isClickDelete && (
                          <>
                            <span className="mr-4 text-[16px]">
                              Are you sure?
                            </span>
                            <button
                              data-testid="btn-delete-from-quiz-yes"
                              onClick={onConfirmDelete}
                              className={getActionButtonStyle(false)}
                            >
                              Yes
                            </button>
                            <span
                              data-testid="btn-delete-from-quiz-no"
                              onClick={() => {
                                setIsClickDelete(false)
                                return false
                              }}
                              className="ml-4 cursor-pointer text-[16px] text-blue-800 underline hover:opacity-[0.8]"
                            >
                              Cancel
                            </span>
                          </>
                        )}
                        {!isClickDelete && (
                          <div className="flex flex-row gap-4 items-center">
                            <span
                              data-testid="btn-delete"
                              onClick={onClickDelete}
                              className="mentem-link-underline"
                            >
                              Delete draft
                            </span>
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                {!selectedQuizId && (
                  <div className="flex flex-col gap-4 w-full">
                    <div className="quiz-create-name-desc-container">
                      <TextareaAutosize
                        id="quiz-create-name-input"
                        placeholder="Type quiz name here"
                        className="quiz-create-name-input"
                        ref={refInputName}
                      />
                      <TextareaAutosize
                        id="quiz-create-description-input"
                        placeholder="Type any notes here"
                        className="quiz-create-description-input"
                        ref={refInputDescription}
                      />
                    </div>
                    <div className="self-end">
                      {isCreating && <Spinner />}
                      {!isCreating && (
                        <button
                          className={StyleUtil.buttonPrimary}
                          onClick={onClickSaveDraft}
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {createMode === CreateMode.AutoGenerate && !selectedQuizId && (
            <QuizGenerator
              courseId={selectedCourseId}
              onCreated={(quizId) => {
                setSelectedQuizId(quizId)
                setCreated(true)
              }}
              onGererating={() => {
                setMessageType(MessageType.Generating)
                setShowWidgetForQuizGenerator(true)
              }}
              onGenerated={(count, total) => {
                setNumberOfQuestionsGenerated(count)
                if (count === total) {
                  setMessageType(MessageType.Completed)
                } else {
                  setMessageType(MessageType.CompletedPartially)
                  setMessageData({ total })
                }
                setShowWidgetForQuizGenerator(true)
              }}
            />
          )}

          {createMode === CreateMode.AutoGenerate && selectedQuizId && (
            <div className="flex flex-row gap-[12px] items-center text-[16px] font-[700]">
              <span>Number of questions to generate</span>
              <span className="rounded-md bg-white px-[12px] py-[6px] shadow-lg">
                {numberOfQuestionsGenerated}
              </span>
            </div>
          )}

          {createMode === CreateMode.ImportFromExcel && !selectedQuizId && (
            <QuizUpload
              courseId={selectedCourseId}
              onCreated={(quizId) => {
                setSelectedQuizId(quizId)
                setCreated(true)
              }}
            />
          )}

          {selectedQuizId && (
            <div className="quiz-create-new-container">
              <QuizDetails
                quizId={selectedQuizId}
                onShowQuestionGeneratorWidget={(data) => {
                  // console.log('onShowQuestionGeneratorWidget', data)
                  if (data) {
                    setGeneratorData(data)
                  }
                  setShowWidgetForQuestionGenerator(true)
                }}
                onShowAddReviewersWidget={(quiz) => {
                  setSelectedQuiz(quiz)
                  setShowAddReviewersWidget(true)
                }}
              />
            </div>
          )}
        </div>
      </SidebarLayout>
      {showWidgetForQuizGenerator && (
        <WidgetQuizGenerator
          messageType={messageType}
          data={messageData}
          onClose={() => setShowWidgetForQuizGenerator(false)}
        />
      )}
      {showWidgetForQuestionGenerator && (
        <WidgetQuestionGenerator
          multiple={false}
          onGenerated={() => {
            setShowWidgetForQuestionGenerator(false)
          }}
          onClose={() => {
            setShowWidgetForQuestionGenerator(false)
          }}
          quizId={generatorData?.quizId || undefined}
          quizQuestion={generatorData?.question || undefined}
          onSaved={() => {
            setGeneratorData(undefined)
            setShowWidgetForQuestionGenerator(false)
          }}
          courseId={generatorData?.courseId}
        />
      )}
      {showAddReviewersWidget && selectedQuiz && (
        <WidgetAddReviewers
          quiz={selectedQuiz}
          onClose={(success: boolean) => {
            setShowAddReviewersWidget(false)
            if (success) {
              // back to quiz list
              navigate(`${Path.quizzes.path}`)
            }
          }}
        />
      )}
    </>
  )
}

export default QuizCreatePage
