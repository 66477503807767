import Spinner from './Spinner'
import { useCallback, useEffect, useRef, useState } from 'react'
import DropDownMenu, { DropDownItem, DropDownMenuRefType } from './DropDownMenu'
import { ToastUtil } from 'utils/ToastUtil'
import { GeneratedQuestion, QuizQuestion } from 'apis/entities/quiz.entity'
import { useAuth } from 'providers/AuthProvider'
import { ArchModule } from 'apis/entities/architecture.entity'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import { useQuizApi } from 'providers/QuizApiProvider'

interface Props {
  courseId: string
  onCreated: (quizId: string) => void
  onGererating: () => void
  onGenerated: (count: number, total: number) => void
}

export default function QuizGenerator({
  courseId,
  onCreated,
  onGererating,
  onGenerated,
}: Props): JSX.Element {
  const { getAuth0UserDetails } = useAuth()
  const [isLoading, setIsLoading] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const { generateMultipleQuestionsV2, createQuiz } = useQuizApi()
  const { getArchCourse } = useArchitectureApi()
  const refNumberOfQuestions = useRef<number>(30)
  const [checkedIds, setCheckedIds] = useState<string[]>([])
  const [selectedModules, setSelectedModules] = useState<ArchModule[]>([])
  const [modules, setModules] = useState<ArchModule[]>([])
  const refDropDownNumberOfQuestions = useRef<DropDownMenuRefType>(null)

  const createQuizByGenerator = useCallback(
    async (questions: GeneratedQuestion[]) => {
      try {
        const user = getAuth0UserDetails()
        const auth0Id = user?.id
        const username = user?.username
        const firstName = user?.firstName
        const email = user?.email

        const quizQuestions = questions.map((q, i) => {
          return {
            text: q.questionText,
            learningOutcome: q.learningOutcome,
            answers: [q.correctAnswer, q.incorrectAnswer1, q.incorrectAnswer2],
            archTopicId: q.archTopicId,
            position: i + 1,
          } as QuizQuestion
        })

        const quiz = await createQuiz(
          ' ', // space for no title
          '',
          auth0Id,
          username,
          firstName,
          email,
          quizQuestions,
          undefined,
          courseId,
        )
        if (quiz) {
          onCreated(quiz.id)
        }
      } catch (error) {
        console.error('Failed to create quiz by generator', error)
      } finally {
        setIsGenerating(false)
        const count = questions.length
        const total = refNumberOfQuestions.current
        onGenerated(count, total)
      }
    },
    [getAuth0UserDetails, createQuiz, courseId, onCreated, onGenerated],
  )

  const onSelectNumberOfQuestions = (item: DropDownItem) => {
    const value = item.value as number
    refNumberOfQuestions.current = value

    if (value === 0) {
      ToastUtil.warning('Please select number of questions')
    }

    if (!canGenerate()) {
      ToastUtil.warning('Please select module and number of questions')
      // reset number of questions
      refNumberOfQuestions.current = 0
      // reset dropdown
      refDropDownNumberOfQuestions.current?.setSelectedItemId('0')
    }

    generateMultipleQuestions()
  }

  const canGenerate = (): boolean => {
    return selectedModules.length > 0 && checkedIds.length > 0
  }

  const generateMultipleQuestions = async () => {
    const topicIds = checkedIds
    // console.log('topicIds', topicIds)
    const numberOfQuestions = refNumberOfQuestions.current
    console.log('numberOfQuestions', numberOfQuestions)
    if (topicIds.length === 0) {
      ToastUtil.warning('Please select a module which has topics')
      return
    }
    if (!numberOfQuestions) {
      ToastUtil.warning('Please select number of questions')
      return
    }

    try {
      setIsGenerating(true)
      onGererating()
      const res = await generateMultipleQuestionsV2(topicIds, numberOfQuestions)
      console.log('res', res)
      const questions = res.questions
      console.log('questions', questions)
      await createQuizByGenerator(questions as GeneratedQuestion[])
    } catch (error) {
      console.error('Failed to generate multiple questions', error)
      ToastUtil.error('Failed to generate questions')
    } finally {
      setIsGenerating(false)
    }
  }

  const onSelectMultipleModules = (e: any) => {
    const checked = e.target.checked
    const moduleId = e.target.value
    const module = modules.find((m) => m.id === moduleId)
    const newSelectedModules = []
    if (module) {
      if (checked) {
        // add to selected modules
        newSelectedModules.push(...selectedModules, { ...module })
        setSelectedModules(newSelectedModules)
      } else {
        // remove from selected modules
        newSelectedModules.push(
          ...selectedModules.filter((m) => m.id !== moduleId),
        )
        setSelectedModules(newSelectedModules)
      }
    }

    // console.log('selectedModules', newSelectedModules)
    // get topic ids from topics in selected modules for generating questions
    const topicIds = newSelectedModules
      .map((m) => m.topics)
      .flat()
      .filter((t) => t.id)
      .map((t) => t.id)
    setCheckedIds(topicIds)
  }

  const fetchCourse = useCallback(async () => {
    try {
      setIsLoading(true)
      const course = await getArchCourse(courseId)
      if (course) {
        setModules(course.modules)
        // reset selected modules
        setSelectedModules([])
        // reset checked ids
        setCheckedIds([])
      }
    } catch (error) {
      console.error('Failed to fetch course', error)
    } finally {
      setIsLoading(false)
    }
  }, [getArchCourse, courseId])

  useEffect(() => {
    fetchCourse()
  }, [fetchCourse])

  return (
    <div className="flex flex-col gap-[12px] w-full">
      {!isGenerating && !isLoading && (
        <>
          <div className="flex flex-col items-start justify-start w-full gap-4">
            <div className="flex flex-col gap-[12px] w-full overflow-auto max-h-[100%]">
              {modules && modules.length > 0 && (
                <div className="flex flex-wrap gap-[12px] w-full">
                  {modules.map((module, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-start gap-[6px] cursor-pointer"
                    >
                      <input
                        id={`module-${module.id}`}
                        type="checkbox"
                        value={module.id}
                        checked={selectedModules.some(
                          (m) => m.id === module.id,
                        )}
                        onChange={(e) => {
                          onSelectMultipleModules(e)
                        }}
                      />
                      <span
                        className="font-roboto text-sm font-semibold normal-case"
                        onClick={() => {
                          // simulate to select input by getting the id
                          // console.log('module.id', module.id)
                          const id = `module-${module.id}`
                          const input = document.getElementById(
                            id,
                          ) as HTMLInputElement
                          if (input) {
                            input.click()
                          }
                        }}
                      >
                        Module {(index + 1).toString().padStart(2, '0')}:{' '}
                        {module.name}
                      </span>
                    </div>
                  ))}
                </div>
              )}
            </div>

            {!isLoading && modules && modules.length === 0 && (
              <p className="self-center">No modules available</p>
            )}
          </div>

          <div className="flex flex-row items-center justify-start gap-4">
            <p className="mentem-widget-title">
              Number of questions to generate
            </p>
            <DropDownMenu
              items={[
                {
                  id: '0',
                  name: '-',
                  value: '0',
                  default: true,
                  isLabel: true,
                },
                // start from 10 to 30 numbers interval 5
                ...Array.from({ length: 5 }, (_, i) => i).map((i) => {
                  const value = i * 5 + 10
                  return {
                    id: value.toString(),
                    name: value.toString(),
                    value: value,
                    default: false,
                  }
                }),
              ]}
              onSelected={onSelectNumberOfQuestions}
              ref={refDropDownNumberOfQuestions}
            />
          </div>
        </>
      )}
      {isLoading && <Spinner />}
    </div>
  )
}
