/* eslint-disable @typescript-eslint/no-unused-vars */
import { GeneratedQuestion } from 'apis/entities/quiz.entity'
import tickSvg from 'images/quiz-tick.svg'
import crossSvg from 'images/quiz-cross.svg'
import minusSvg from 'images/quiz-minus.svg'
import plusSvg from 'images/quiz-plus.svg'
import { StyleUtil } from 'utils/StyleUtil'
import { useState, KeyboardEvent } from 'react'
import { ToastUtil } from 'utils/ToastUtil'
import React from 'react'
import ButtonSpinner from './ButtonSpinner'
import { TextareaAutosize } from '@mui/base'
import { useAppSelector, useAppDispatch } from 'app/hooks'
import { updateGeneratedQuestion } from 'app/QuestionsSlice'
import { useQuizApi } from 'providers/QuizApiProvider'

type QuestionEditViewForAuthoringProps = {
  index: number
  documentId: string
  locked: boolean
  disabled: boolean
}

export default function QuestionEditViewForAuthoring({
  index,
  documentId,
  locked,
  disabled,
}: QuestionEditViewForAuthoringProps) {
  const { generateQuestions } = useQuizApi()
  const [isRegenerating, setIsRegenerating] = useState(false)
  const [isMinimized, setIsMinimized] = useState(false)
  const generatedQuestions = useAppSelector(
    (state) => state.questions.generatedQuestions,
  )
  const dispatch = useAppDispatch()
  const refQuestionText = React.useRef<HTMLTextAreaElement>(null)
  const refCorrectAnswer = React.useRef<HTMLTextAreaElement>(null)
  const refIncorrectAnswer1 = React.useRef<HTMLTextAreaElement>(null)
  const refIncorrectAnswer2 = React.useRef<HTMLTextAreaElement>(null)
  const refIncorrectFeedback = React.useRef<HTMLTextAreaElement>(null)

  const onClickRegenerate = async () => {
    try {
      setIsRegenerating(true)
      const regeneratedQuestions: GeneratedQuestion[] = await generateQuestions(
        documentId,
        1,
      )
      if (!regeneratedQuestions || regeneratedQuestions.length === 0) {
        throw new Error('Failed to regenerate question')
      }
      const regeneratedQuestion = regeneratedQuestions[0]
      console.log('regeneratedQuestion', regeneratedQuestion)
      if (regeneratedQuestion) {
        // update the redux store
        dispatch(
          updateGeneratedQuestion({
            index,
            generatedQuestion: regeneratedQuestion,
          }),
        )
        // update ref value
        // console.log('refQuestionText', refQuestionText.current)
        refQuestionText.current!.value = regeneratedQuestion.questionText
        refCorrectAnswer.current!.value = regeneratedQuestion.correctAnswer
        refIncorrectAnswer1.current!.value =
          regeneratedQuestion.incorrectAnswer1
        refIncorrectAnswer2.current!.value =
          regeneratedQuestion.incorrectAnswer2
        refIncorrectFeedback.current!.value =
          regeneratedQuestion.incorrectFeedback

        ToastUtil.success('Question regenerated')
      } else {
        ToastUtil.error('Failed to regenerate question')
      }
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to regenerate question')
    } finally {
      setIsRegenerating(false)
    }
  }

  const onClickMinus = () => {
    setIsMinimized(!isMinimized)
  }

  const handleKeyDownForTextArea = (
    event: KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
    }
  }

  const handleKeyUpForTextArea = (
    event: React.KeyboardEvent<HTMLTextAreaElement>,
  ) => {
    // check if target is empty, change color
    if (event.currentTarget.value === '') {
      event.currentTarget.style.color = '#92929d'
    } else {
      event.currentTarget.style.color = '#000'
    }

    // update the redux store
    const updatedQuestion: GeneratedQuestion = {
      questionText: refQuestionText.current!.value,
      correctAnswer: refCorrectAnswer.current!.value,
      incorrectAnswer1: refIncorrectAnswer1.current!.value,
      incorrectAnswer2: refIncorrectAnswer2.current!.value,
      incorrectFeedback: refIncorrectFeedback.current!.value,
      documentId: documentId,
      // dummy values
      incorrectAnswer3: '',
      learningOutcome: '',
    }
    dispatch(
      updateGeneratedQuestion({
        index,
        generatedQuestion: updatedQuestion,
      }),
    )
  }

  const isValidQuestion = () => {
    return (
      generatedQuestions &&
      generatedQuestions.length > 0 &&
      generatedQuestions[index]
    )
  }

  if (!isValidQuestion()) {
    return <></>
  }

  return (
    <div className="flex flex-col gap-2 p-1 bg-white">
      <div className="flex flex-row pl-2 pr-4">
        <div className="pr-4 grow flex flex-row gap-[12px]">
          <TextareaAutosize
            ref={refQuestionText}
            className={`quiz-editable-question ${
              generatedQuestions[index].questionText.length === 0
                ? 'quiz-editable-empty'
                : 'quiz-editable-text'
            }`}
            defaultValue={generatedQuestions[index].questionText || ''}
            onKeyDown={handleKeyDownForTextArea}
            onKeyUp={handleKeyUpForTextArea}
            placeholder="Type question here"
            disabled={locked || disabled}
          />
        </div>
        <div className="flex flex-row gap-4 items-center">
          {!locked && (
            <button
              type="button"
              className={`${
                isMinimized
                  ? StyleUtil.buttonSecondary
                  : StyleUtil.buttonPrimary
              }`}
              onClick={onClickRegenerate}
              disabled={isRegenerating || disabled}
            >
              {isRegenerating ? <ButtonSpinner /> : 'Regenerate'}
            </button>
          )}
          <div
            className="cursor-pointer h-[16px] w-[16px] flex flex-col items-center justify-center"
            tabIndex={-1}
            onClick={onClickMinus}
          >
            {isMinimized ? (
              <img src={plusSvg} alt="expand" />
            ) : (
              <img src={minusSvg} alt="minimise" />
            )}
          </div>
        </div>
      </div>
      <div className="quiz-separator mb-2" />
      {!isMinimized && (
        <div className="flex flex-col gap-4 pl-2">
          <div className="flex flex-row items-center gap-4">
            <img src={tickSvg} alt="answer-icon" />
            <TextareaAutosize
              ref={refCorrectAnswer}
              className={`quiz-editable-answer ${
                generatedQuestions[index].correctAnswer?.length === 0
                  ? 'quiz-editable-empty'
                  : 'quiz-editable-text'
              }`}
              defaultValue={generatedQuestions[index].correctAnswer}
              onKeyDown={handleKeyDownForTextArea}
              onKeyUp={handleKeyUpForTextArea}
              placeholder={'Type correct answer here'}
              disabled={locked || disabled}
            />
          </div>
          <div className="flex flex-row items-center gap-4">
            <img src={crossSvg} alt="answer-icon" />
            <TextareaAutosize
              ref={refIncorrectAnswer1}
              className={`quiz-editable-answer ${
                generatedQuestions[index].incorrectAnswer1?.length === 0
                  ? 'quiz-editable-empty'
                  : 'quiz-editable-text'
              }`}
              defaultValue={generatedQuestions[index].incorrectAnswer1}
              onKeyDown={handleKeyDownForTextArea}
              onKeyUp={handleKeyUpForTextArea}
              placeholder={'Type incorrect answer here'}
              disabled={locked || disabled}
            />
          </div>
          <div className="flex flex-row items-center gap-4">
            <img src={crossSvg} alt="answer-icon" />
            <TextareaAutosize
              ref={refIncorrectAnswer2}
              className={`quiz-editable-answer ${
                generatedQuestions[index].incorrectAnswer2?.length === 0
                  ? 'quiz-editable-empty'
                  : 'quiz-editable-text'
              }`}
              defaultValue={generatedQuestions[index].incorrectAnswer2}
              onKeyDown={handleKeyDownForTextArea}
              onKeyUp={handleKeyUpForTextArea}
              placeholder={'Type incorrect answer here'}
              disabled={locked || disabled}
            />
          </div>
          <div className="flex flex-row items-center gap-2">
            <span className="font-bold">Feedback:</span>
            <TextareaAutosize
              ref={refIncorrectFeedback}
              className={`quiz-editable-answer-feedback flex-1 ${
                generatedQuestions[index].incorrectFeedback?.length === 0
                  ? 'quiz-editable-empty'
                  : 'quiz-editable-text'
              }`}
              defaultValue={generatedQuestions[index].incorrectFeedback}
              onKeyDown={handleKeyDownForTextArea}
              onKeyUp={handleKeyUpForTextArea}
              placeholder={'Type incorrect feedback here'}
              disabled={locked || disabled}
            />
          </div>
        </div>
      )}
    </div>
  )
}
