import { StyleUtil } from 'utils/StyleUtil'
import closeSvg from '../images/close2.svg'
import tickLargeSvg from '../images/tick-large.svg'
import warningSvg from '../images/warning.svg'
import LargeSpinner from './LargeSpinner'

export enum MessageType {
  Generating = 'Generating',
  Completed = 'Completed',
  CompletedPartially = 'CompletedPartially',
}

interface Props {
  messageType: MessageType
  data: any
  onClose: () => void
}

export default function WidgetQuizGenerator({
  messageType,
  data,
  onClose,
}: Props): JSX.Element {
  return (
    <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center">
      <div className="max-h-[50%] w-[572px] flex flex-col bg-white shadow-xl rounded-[12px] p-[24px] gap-[12px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={onClose}
        />
        {messageType === MessageType.Generating && (
          <LargeSpinner text="Generating questions..." />
        )}
        {messageType === MessageType.Completed && (
          <div className="flex flex-col gap-[36px] w-full items-center justify-center text-center">
            <div>
              <p className="text-[20px] font-bold text-primary">
                Questions generated
              </p>
              <p className="text-[20px] font-bold text-[#000]">
                You now have a draft quiz to review and edit - do not finalise
                or send for review without editing first!
              </p>
            </div>
            <img src={tickLargeSvg} alt="tick" />
          </div>
        )}
        {messageType === MessageType.CompletedPartially && (
          <div className="flex flex-col gap-[24px] w-full items-center justify-center text-center pb-[12px]">
            <p className="text-[20px] font-bold text-primary">
              Questions generated
            </p>
            <p className="flex flex-wrap items-center gap-1 text-[18px] font-bold text-[#000]">
              <img src={warningSvg} alt="warning" />
              Not enough topics selected to generate all{' '}
              <span className="text-[#F00]">{data?.total}</span> questions
            </p>
            <button className={StyleUtil.buttonPrimary} onClick={onClose}>
              Ok
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
