import {
  ArchCheckTopicsResponse,
  ArchCheckTopicsResult,
} from 'apis/entities/architecture.entity'
import Svg, { Icon, IconClass } from 'components/Svg'
import { StyleUtil } from 'utils/StyleUtil'
import sortSvg from 'images/sort.svg'
import sort2Svg from 'images/sort2.svg'
import tickPng from 'images/check_mark_32.png'
import crossPng from 'images/cross_mark_32.png'
import { useMemo, useState } from 'react'
import {
  Direction,
  DirectionAsc,
  DirectionDesc,
  tooltipStyle,
  tooltipStyleReverse,
} from 'utils/TableUtils'
import { Tooltip } from 'react-tooltip'
import React from 'react'

enum SortBy {
  ModuleName = 'moduleName',
  TopicName = 'topicName',
  TopicLink = 'topicLink',
}

interface Props {
  result: ArchCheckTopicsResponse
  onClose: () => void
}

export default function WidgetArchCheckTopicsResult({
  result,
  onClose,
}: Props) {
  const [sortConfig, setSortConfig] = useState<{
    key: SortBy
    direction: Direction
  } | null>(null)
  const [orderByModuleName, setOrderByModuleName] = useState<Direction>('')
  const [orderByTopicName, setOrderByTopicName] = useState<Direction>('')
  const [orderByTopicLink, setOrderByTopicLink] = useState<Direction>('')

  const sortedResults = useMemo(() => {
    if (!result) return []

    let sortedResults = [...result.results]

    if (sortConfig) {
      sortedResults = sortedResults.sort((a, b) => {
        let aValue = ''
        let bValue = ''
        let aNumber = 0
        let bNumber = 0
        switch (sortConfig.key) {
          case SortBy.ModuleName:
            aNumber = parseInt(a.module.moduleNumber)
            bNumber = parseInt(b.module.moduleNumber)
            if (aNumber < bNumber) {
              return sortConfig.direction === DirectionAsc ? 1 : -1
            }
            if (aNumber > bNumber) {
              return sortConfig.direction === DirectionAsc ? -1 : 1
            }
            break
          case SortBy.TopicName:
            aNumber = parseFloat(a.topic.topicNumber)
            bNumber = parseFloat(b.topic.topicNumber)
            if (aNumber < bNumber) {
              return sortConfig.direction === DirectionAsc ? 1 : -1
            }
            if (aNumber > bNumber) {
              return sortConfig.direction === DirectionAsc ? -1 : 1
            }
            break
          case SortBy.TopicLink:
            // empty or null link should be at the end
            aValue = a.topic.link ? a.topic.link : 'zzzzzz'
            bValue = b.topic.link ? b.topic.link : 'zzzzzz'
            break
          default:
            break
        }

        if (aValue < bValue) {
          return sortConfig.direction === DirectionAsc ? -1 : 1
        }
        if (aValue > bValue) {
          return sortConfig.direction === DirectionAsc ? 1 : -1
        }
        return 0
      })
    }

    return sortedResults
  }, [result, sortConfig])

  const onClickSort = (key: SortBy) => {
    let ascending = true
    if (
      sortConfig &&
      sortConfig.key === key &&
      sortConfig.direction === DirectionAsc
    ) {
      ascending = false
    }

    switch (key) {
      case SortBy.ModuleName:
        setOrderByModuleName(ascending ? DirectionAsc : DirectionDesc)
        break
      case SortBy.TopicName:
        setOrderByTopicName(ascending ? DirectionAsc : DirectionDesc)
        break
      case SortBy.TopicLink:
        setOrderByTopicLink(ascending ? DirectionAsc : DirectionDesc)
        break
      default:
        break
    }

    setSortConfig({ key, direction: ascending ? DirectionAsc : DirectionDesc })
  }

  const getTooptipStyle = (key: SortBy): any => {
    switch (key) {
      case SortBy.ModuleName:
        return orderByModuleName === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.TopicName:
        return orderByTopicName === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      case SortBy.TopicLink:
        return orderByTopicLink === DirectionAsc
          ? tooltipStyleReverse
          : tooltipStyle
      default:
        return tooltipStyle
    }
  }

  const getSortingText = (key: SortBy): string => {
    switch (key) {
      case SortBy.ModuleName:
        if (orderByModuleName === DirectionAsc) return 'Sort 1 to 99'
        return 'Sort 99 to 1'
      case SortBy.TopicName:
        if (orderByTopicName === DirectionAsc) return 'Sort 1 to 99'
        return 'Sort 99 to 1'
      case SortBy.TopicLink:
        if (orderByTopicLink === DirectionAsc) return 'Sort Z to A'
        return 'Sort A to Z'
      default:
        return ''
    }
  }

  const renderResult = (r: ArchCheckTopicsResult) => {
    return (
      <td className="p-2 bg-[#fff] border-[1px] border-[#F3F3F5]">
        <div className="flex items-center justify-center">
          <img
            src={r.result.success ? tickPng : crossPng}
            width="16"
            alt="result"
          />
        </div>
      </td>
    )
  }

  const renderModuleName = (r: ArchCheckTopicsResult, index: number) => {
    // if missing link return empty
    if (!r.topic.link) {
      return <td className="p-2 bg-[#F3F3F5] border-[1px] border-[#fff]"></td>
    }

    // validation field is `module`
    const isMatched = r.result.validations?.find(
      (v) => v.field === 'module',
    )?.matched
    const color = isMatched ? '#0D0D0D' : '#FF0000'
    return (
      <td
        className="p-2 text-center bg-[#F3F3F5] border-[1px] border-[#fff]"
        style={{ color }}
      >
        <div
          data-tooltip-id={`tooltip-module-${r.module.id}-${index}`}
          className="flex flex-col mentem-scrollbar-2 pr-[1px]"
          style={{
            // display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            whiteSpace: 'normal',
            maxHeight: '4.5em', // approximate height for 3 lines of text
            overflowY: 'auto', // enable scrollbar if content exceeds
          }}
        >
          <span>Module {r.module.moduleNumber}</span>
          <span>{r.module.name}</span>
        </div>
      </td>
    )
  }

  const renderTopicName = (r: ArchCheckTopicsResult) => {
    // if missing link return empty
    if (!r.topic.link) {
      return <td className="p-2 bg-[#fff] border-[1px] border-[#F3F3F5]"></td>
    }

    // validation field is `topic`
    const isMatched = r.result.validations?.find(
      (v) => v.field === 'title',
    )?.matched
    const color = isMatched ? '#0D0D0D' : '#FF0000'
    return (
      <td
        className="p-2 bg-[#fff] border-[1px] border-[#F3F3F5]"
        style={{ color }}
      >
        <span
          data-tooltip-id={`tooltip-topic-${r.topic.id}`}
          className="mentem-scrollbar-2 pr-[1px]"
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            whiteSpace: 'normal',
            maxHeight: '4.5em', // approximate height for 3 lines of text
            overflowY: 'auto', // enable scrollbar if content exceeds
          }}
        >
          {r.topic.topicNumber} {r.topic.name}
        </span>
      </td>
    )
  }

  const renderLearningOutcome = (r: ArchCheckTopicsResult) => {
    // if missing link return empty
    if (!r.topic.link) {
      return <td className="p-2 bg-[#F3F3F5] border-[1px] border-[#fff]"></td>
    }

    // validation field is `learningOutcomes`
    const isMatched = r.result.validations?.find(
      (v) => v.field === 'learningOutcomes',
    )?.matched
    const color = isMatched ? '#0D0D0D' : '#FF0000'
    return (
      <td
        className="p-2 bg-[#F3F3F5] border-[1px] border-[#fff]"
        style={{ color }}
      >
        <span
          data-tooltip-id={`tooltip-learning-outcome-${r.topic.id}`}
          className="mentem-scrollbar-2 pr-[1px]"
          style={{
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            whiteSpace: 'normal',
            maxHeight: '4.5em', // approximate height for 3 lines of text
            overflowY: 'auto', // enable scrollbar if content exceeds
          }}
        >
          {r.topic.learningOutcome
            ? r.topic.learningOutcome.split('\n').map((line, i) => (
                <React.Fragment key={i}>
                  {line}
                  <br />
                </React.Fragment>
              ))
            : 'LO missing'}
        </span>
      </td>
    )
  }

  const renderTopicLink = (r: ArchCheckTopicsResult) => {
    // validation field is `domain`
    const isMatched = r.result.validations?.find(
      (v) => v.field === 'domain',
    )?.matched

    const color = isMatched ? '#105EFB' : '#FF0000'
    return (
      <td className="p-2 bg-[#fff] border-[1px] border-[#F3F3F5]">
        <a
          href={r.topic.link}
          target="_blank"
          rel="noreferrer"
          className="mentem-scrollbar-2 pr-[1px]"
          style={{
            color,
            display: '-webkit-box',
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            whiteSpace: 'normal',
            maxHeight: '4.5em', // approximate height for 3 lines of text
            overflowY: 'auto', // enable scrollbar if content exceeds
          }}
          data-tooltip-id={`tooltip-topic-link-${r.topic.id}`}
        >
          {r.topic.link ? r.topic.link : 'Link missing'}
        </a>
      </td>
    )
  }

  return (
    <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex items-center justify-center">
      <div className="h-3/4 w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[24px]">
        <div className="flex flex-row">
          <div className="grow" />
          <div className="self-end cursor-pointer" onClick={onClose}>
            <Svg icon={Icon.Close} className={IconClass.FillBlack2} />
          </div>
        </div>
        <p className="text-[20px] font-[700] font-roboto">
          Topic text has been cross-checked. Black text matches architecture,{' '}
          <span className="text-[#ff0000]">red</span> text is inconsistent.
          <br />
          Mouse-over the text to compare to architecture.
        </p>
        {result && result.results.length > 0 && (
          <>
            <div className="overflow-y-auto border-[1px] border-[#D5D5DB] mentem-scrollbar-2">
              <table className="mentem-table">
                <thead>
                  <tr>
                    <th
                      className={`${StyleUtil.stickyTableHeader1} w-[5%] min-w-[60px]`}
                    >
                      Result
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader2} h-[55px] w-[25%] min-w-[150px]`}
                    >
                      <div className="flex flex-row items-center px-2">
                        <span className="grow">Module name</span>
                        <img
                          className="cursor-pointer"
                          src={sort2Svg}
                          alt="sort"
                          onClick={() => onClickSort(SortBy.ModuleName)}
                          data-tooltip-id="tooltip-module-name"
                        />
                      </div>
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader1} w-[25%] min-w-[150px]`}
                    >
                      <div className="flex flex-row items-center px-2">
                        <span className="grow">Topic name</span>
                        <img
                          className="cursor-pointer"
                          src={sortSvg}
                          alt="sort"
                          onClick={() => onClickSort(SortBy.TopicName)}
                          data-tooltip-id="tooltip-topic-name"
                        />
                      </div>
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader2} w-[30%] min-w-[200px]`}
                    >
                      Learning outcome
                    </th>
                    <th
                      className={`${StyleUtil.stickyTableHeader1} w-[15%] min-w-[150px]`}
                    >
                      <div className="flex flex-row items-center px-2">
                        <span className="grow">Topic link</span>
                        <img
                          className="cursor-pointer pl-2 pr-2"
                          src={sortSvg}
                          alt="sort"
                          onClick={() => onClickSort(SortBy.TopicLink)}
                          data-tooltip-id="tooltip-topic-link"
                        />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedResults.map((r, index) => (
                    <tr key={index} className="text-[14px] font-[600]">
                      {renderResult(r)}
                      {renderModuleName(r, index)}
                      {renderTopicName(r)}
                      {renderLearningOutcome(r)}
                      {renderTopicLink(r)}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {[
              { id: 'tooltip-module-name', key: SortBy.ModuleName },
              { id: 'tooltip-topic-name', key: SortBy.TopicName },
              { id: 'tooltip-topic-link', key: SortBy.TopicLink },
            ].map((item, index) => (
              <Tooltip
                key={`tooltip-header-${index}`}
                id={item.id}
                className="mentem-tooltip"
                style={getTooptipStyle(item.key)}
                place="top"
                noArrow={true}
              >
                {getSortingText(item.key)}
              </Tooltip>
            ))}
            {result.results.map((r, index) => {
              const isMatchedModule = r.result.validations?.find(
                (v) => v.field === 'module',
              )?.matched
              const isMatchedTopic = r.result.validations?.find(
                (v) => v.field === 'title',
              )?.matched
              const isMatchedLearningOutcome = r.result.validations?.find(
                (v) => v.field === 'learningOutcomes',
              )?.matched
              const isMatchedTopicLink = r.result.validations?.find(
                (v) => v.field === 'domain',
              )?.matched
              const expectedModuleName = r.result.validations?.find(
                (v) => v.field === 'module',
              )?.expected
              const expectedTopicName = r.result.validations?.find(
                (v) => v.field === 'title',
              )?.expected
              const expectedLearningOutcome = r.result.validations?.find(
                (v) => v.field === 'learningOutcomes',
              )?.expected
              const expectedTopicLink = r.result.validations?.find(
                (v) => v.field === 'domain',
              )?.expected

              return (
                <React.Fragment key={index}>
                  {!isMatchedModule && expectedModuleName && (
                    <Tooltip
                      key={`tooltip-module-${r.module.id}-${index}`}
                      id={`tooltip-module-${r.module.id}-${index}`}
                      className="mentem-tooltip-checker"
                      style={tooltipStyle}
                      place="top"
                      noArrow={true}
                    >
                      <span className="text-[#3C415D]">
                        {expectedModuleName}
                      </span>
                    </Tooltip>
                  )}
                  {!isMatchedTopic && expectedTopicName && (
                    <Tooltip
                      key={`tooltip-topic-${r.topic.id}`}
                      id={`tooltip-topic-${r.topic.id}`}
                      className="mentem-tooltip-checker"
                      style={tooltipStyle}
                      place="top"
                      noArrow={true}
                    >
                      <span className="text-[#3C415D]">
                        {r.topic.topicNumber} {expectedTopicName}
                      </span>
                    </Tooltip>
                  )}
                  {!isMatchedLearningOutcome && expectedLearningOutcome && (
                    <Tooltip
                      key={`tooltip-learning-outcome-${r.topic.id}`}
                      id={`tooltip-learning-outcome-${r.topic.id}`}
                      className="mentem-tooltip-checker"
                      style={tooltipStyle}
                      place="top"
                      noArrow={true}
                    >
                      <div className="text-[#3C415D]">
                        {expectedLearningOutcome.split('\n').map((line, i) => (
                          <React.Fragment key={i}>
                            {line}
                            <br />
                          </React.Fragment>
                        ))}
                      </div>
                    </Tooltip>
                  )}
                  {!isMatchedTopicLink && expectedTopicLink && (
                    <Tooltip
                      key={`tooltip-topic-link-${r.topic.id}`}
                      id={`tooltip-topic-link-${r.topic.id}`}
                      className="mentem-tooltip-link"
                      style={tooltipStyle}
                      place="top"
                      noArrow={true}
                    >
                      <span className="text-[#328CFC]">
                        {expectedTopicLink}
                      </span>
                    </Tooltip>
                  )}
                </React.Fragment>
              )
            })}
          </>
        )}
      </div>
    </div>
  )
}
