import { useCallback, useEffect, useMemo, useState } from 'react'
import closeSvg from '../images/close2.svg'
import Checkbox, { CheckboxSize, CheckboxTheme } from 'components/Checkbox'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import { Quiz } from 'apis/entities/quiz.entity'
import Spinner from './Spinner'
import { StyleUtil } from 'utils/StyleUtil'
import { getDateTimeString } from 'utils/StringUtil'
import { QuizStatusText } from './QuizStatusText'
import { ToastUtil } from 'utils/ToastUtil'
import ButtonSpinner from './ButtonSpinner'
import Emitter, { Events } from 'core/emitter'
import SearchInput from './SearchInput'

interface Props {
  courseId: string
  onClose: () => void
}

export default function WidgetUnassignedAssessments({
  courseId,
  onClose,
}: Props): JSX.Element {
  const [isAssigning, setIsAssigning] = useState<boolean>(false)
  const [isFetching, setIsFetching] = useState<boolean>(true)
  const [selectedAssessmentIds, setSelectedAssessmentIds] = useState<string[]>(
    [],
  ) // quiz ids
  const [quizess, setQuizess] = useState<Quiz[]>([])
  const { getArchUnassignedQuizzesForTable, assignQuizToCourse } =
    useArchitectureApi()
  const [searchText, setSearchText] = useState<string>('')

  const filteredQuizess = useMemo(() => {
    return quizess.filter((quiz) =>
      quiz.name.toLowerCase().includes(searchText.toLowerCase()),
    )
  }, [quizess, searchText])

  const onInputChangedSearch = (value: string) => {
    setSearchText(value)
  }

  const onEnterSearch = async () => {}

  const assignQuizzes = useCallback(async () => {
    try {
      setIsAssigning(true)
      await assignQuizToCourse(selectedAssessmentIds, courseId)
      ToastUtil.success('Assessments assigned successfully')
      // send event to parent to refresh the list
      Emitter.emit(Events.AddedUnassignedAssignments, selectedAssessmentIds)
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsAssigning(false)
    }
  }, [assignQuizToCourse, courseId, onClose, selectedAssessmentIds])

  const fetchQuizess = useCallback(async () => {
    try {
      setIsFetching(true)
      const response = await getArchUnassignedQuizzesForTable()
      if (!response) {
        throw new Error('Failed to fetch unassigned quizzes')
      }
      if (!response.quizzes) {
        throw new Error('Failed to fetch unassigned quizzes')
      }
      setQuizess(response.quizzes)
    } catch (error) {
      console.error(error)
    } finally {
      setIsFetching(false)
    }
  }, [getArchUnassignedQuizzesForTable])

  useEffect(() => {
    fetchQuizess()
  }, [fetchQuizess])

  return (
    <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center">
      <div className="max-h-[90vh] min-h-[50vh] w-1/2 flex flex-col bg-white shadow-xl rounded-[12px] p-[24px] gap-[12px] pb-[24px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={onClose}
        />
        {isFetching && <Spinner />}
        {!isFetching && (
          <div className="flex flex-col gap-[12px]">
            <div className="flex flex-row gap-[12px] cursor-pointer items-center px-2">
              <SearchInput
                placeholder="Type keywords here"
                onInputChanged={onInputChangedSearch}
                onEnter={onEnterSearch}
                isSearching={false}
              />
            </div>
            <div className="overflow-y-auto h-[40vh] mentem-scrollbar-2 px-2">
              <ul className="flex flex-col border">
                {filteredQuizess.length > 0 &&
                  filteredQuizess.map((quiz) => (
                    <li
                      key={quiz.id}
                      style={{
                        //   borderLeft: '1px solid #D9D9D9C2',
                        //   borderRight: '1px solid #D9D9D9C2',
                        borderBottom: '1px solid #D9D9D9C2',
                        paddingLeft: '8px',
                        paddingRight: '8px',
                      }}
                    >
                      <div className="pl-[12px] flex flex-row items-center p-[12px]">
                        <Checkbox
                          onChange={(checked) => {
                            if (checked) {
                              setSelectedAssessmentIds((prev) => [
                                ...prev,
                                quiz.id,
                              ])
                            } else {
                              setSelectedAssessmentIds((prev) =>
                                prev.filter((id) => id !== quiz.id),
                              )
                            }
                          }}
                          size={CheckboxSize.Small}
                          theme={CheckboxTheme.Primary}
                          id={quiz.id}
                          checked={selectedAssessmentIds.includes(quiz.id)}
                        />
                        <div className="w-[70%] pl-[6px] text-[12px] font-[600]">
                          {quiz.name}
                        </div>
                        <div className="arch-topic-last-updated-at">
                          <span>Last updated:</span>
                          <span>{getDateTimeString(quiz.updatedAt)}</span>
                        </div>
                        <QuizStatusText quiz={quiz} />
                      </div>
                    </li>
                  ))}
                {filteredQuizess.length === 0 && (
                  <div className="flex justify-center items-center h-[38vh]">
                    <span className="text-[14px] text-[#BDBDBD]">
                      No assessments found
                    </span>
                  </div>
                )}
              </ul>
            </div>
            <div className="self-end">
              <button
                className={StyleUtil.buttonPrimary}
                onClick={() => {
                  if (selectedAssessmentIds.length === 0) {
                    ToastUtil.warning('Please select at least one assessment')
                    return
                  }
                  assignQuizzes()
                }}
              >
                {isAssigning ? <ButtonSpinner /> : ' Add to current course'}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
