import {
  ArchCheckTopicsResponse,
  ArchCourseForTable,
  ArchModule,
  ArchTopic,
} from 'apis/entities/architecture.entity'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import { useCallback, useEffect, useRef, useState, KeyboardEvent } from 'react'
import { Role, useAuth } from 'providers/AuthProvider'
import Spinner from './Spinner'
import { StyleUtil } from 'utils/StyleUtil'
import Svg, { Icon, IconClass } from 'components/Svg'
import Emitter, { Events } from 'core/emitter'
import { ArchActionType, getExportFileNameForCourse } from 'utils/ArchUtil'
import { ToastUtil } from 'utils/ToastUtil'
import { TextareaAutosize } from '@mui/base'
import React from 'react'
import ButtonSpinner from './ButtonSpinner'
import tickPng from 'images/check_mark_32.png'
import crossPng from 'images/cross_mark_32.png'
import placeholderPng from 'images/placeholder-32.png'
import { Tooltip } from 'react-tooltip'
import { tooltipStyle } from 'utils/TableUtils'
import WidgetArchCheckTopicsResult from './WidgetArchCheckTopicsResult'
import { Quiz } from 'apis/entities/quiz.entity'
import { getDateTimeString } from 'utils/StringUtil'
import { QuizStatusText } from './QuizStatusText'

interface ArchCourseDetailsViewProps {
  course: ArchCourseForTable
  onClose: () => void
}

export default function WidgetArchCourseDetailsView({
  course,
  onClose,
}: ArchCourseDetailsViewProps) {
  const { isLogged, getRole } = useAuth()
  const isAdmin = getRole() === Role.Admin || getRole() === Role.SuperAdmin
  const {
    getArchCourse,
    updateArchTopic,
    exportArchExcel,
    checkTopics,
    getCheckTopicsResults,
  } = useArchitectureApi()
  const [modules, setModules] = useState<ArchModule[]>([])
  const [quizzes, setQuizzes] = useState<Quiz[]>([])
  const [isFetchingCourse, setIsFetchingCourse] = useState(false)
  const [highlightTopicIds, setHighlightTopicIds] = useState<string[]>([])
  const [collapsedModuleIds, setCollapsedModuleIds] = useState<string[]>([])
  const refTimeoutDict = useRef<{ [index: string]: NodeJS.Timeout }>({})
  const timeoutForSave = 500
  const [editedModeCells, setEditedModeCells] = useState<{
    [id: string]: Boolean
  }>({})
  const [isCheckingTopics, setIsCheckingTopics] = useState(false)
  const [result, setResult] = useState<ArchCheckTopicsResponse | null>(null)
  const [showResult, setShowResult] = useState(false)
  const [checkTopicsResult, setCheckTopicsResult] =
    useState<ArchCheckTopicsResponse | null>(null)
  const [isCollapsedAssessments, setIsCollapsedAssessments] = useState(false)

  const handleKeyUpForTextArea = (e: KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
    }
  }

  const resetTimeout = (
    index: string,
    timeout?: NodeJS.Timeout | undefined,
  ) => {
    if (refTimeoutDict.current[index]) {
      clearTimeout(refTimeoutDict.current[index])
    }
    if (timeout !== undefined && timeout !== null) {
      refTimeoutDict.current[index] = timeout
    }
  }

  const updateLocalTopicName = useCallback(
    (topicId: string, name: string) => {
      const newModules = [...modules]
      const moduleIndex = newModules.findIndex((m) =>
        m.topics.some((t) => t.id === topicId),
      )
      // console.log('moduleIndex', moduleIndex)
      if (moduleIndex === -1) {
        return
      }
      const module = newModules[moduleIndex]
      const topicIndex = module.topics.findIndex((t) => t.id === topicId)
      // console.log('topicIndex', topicIndex)
      if (topicIndex === -1) {
        return
      }
      const newTopics = [...module.topics]
      newTopics[topicIndex].name = name
      module.topics = newTopics
      setModules(newModules)
    },
    [modules],
  )

  const updateLocalTopicLearningOutcome = useCallback(
    (topicId: string, learningOutcome: string) => {
      const newModules = [...modules]
      const moduleIndex = newModules.findIndex((m) =>
        m.topics.some((t) => t.id === topicId),
      )
      if (moduleIndex === -1) {
        return
      }
      const module = newModules[moduleIndex]
      const topicIndex = module.topics.findIndex((t) => t.id === topicId)
      if (topicIndex === -1) {
        return
      }
      const newTopics = [...module.topics]
      newTopics[topicIndex].learningOutcome = learningOutcome
      module.topics = newTopics
      setModules(newModules)
    },
    [modules],
  )

  const updateLocalTopicUpdatedAt = useCallback(
    (topicId: string, updatedAt: Date) => {
      const newModules = [...modules]
      const moduleIndex = newModules.findIndex((m) =>
        m.topics.some((t) => t.id === topicId),
      )
      if (moduleIndex === -1) {
        return
      }
      const module = newModules[moduleIndex]
      const topicIndex = module.topics.findIndex((t) => t.id === topicId)
      if (topicIndex === -1) {
        return
      }
      const newTopics = [...module.topics]
      newTopics[topicIndex].updatedAt = updatedAt
      module.topics = newTopics
      setModules(newModules)
    },
    [modules],
  )

  const updateTopicLearningOutcome = useCallback(
    async (
      topicId: string,
      learningOutcome: string,
      toast: boolean = false,
    ) => {
      try {
        const topic = await updateArchTopic(
          topicId,
          undefined,
          learningOutcome,
          undefined,
        )
        if (toast) {
          ToastUtil.success('Learning outcome updated')
        }
        // console.log('updateArchTopic result', topic)
        const updatedAt = topic.updatedAt
        // update local state
        updateLocalTopicLearningOutcome(topic.id, learningOutcome)
        updateLocalTopicUpdatedAt(topicId, updatedAt)
      } catch (error) {
        ToastUtil.error('Update learning outcome failed')
        console.error(error)
      }
    },
    [
      updateArchTopic,
      updateLocalTopicLearningOutcome,
      updateLocalTopicUpdatedAt,
    ],
  )

  const updateTopicName = useCallback(
    async (topicId: string, name: string, toast: boolean = false) => {
      try {
        const topic = await updateArchTopic(topicId, name, undefined, undefined)
        if (toast) {
          ToastUtil.success('Topic name updated')
        }
        // update local state
        updateLocalTopicName(topic.id, name)
        updateLocalTopicUpdatedAt(topicId, topic.updatedAt)
      } catch (error) {
        ToastUtil.error('Update topic name failed')
        console.error(error)
      }
    },
    [updateArchTopic, updateLocalTopicName, updateLocalTopicUpdatedAt],
  )

  const fetchCheckTopicsResults = useCallback(async () => {
    try {
      const res = await getCheckTopicsResults(course.id)
      setCheckTopicsResult(res)
    } catch (error) {
      console.error(error)
    }
  }, [course.id, getCheckTopicsResults])

  const fetchCourse = useCallback(async () => {
    try {
      setIsFetchingCourse(true)
      const res = await getArchCourse(course.id)
      setModules(res.modules || [])
      setQuizzes(res.quizzes || [])
    } catch (error) {
      console.log('Error fetching course', error)
    } finally {
      setIsFetchingCourse(false)
    }
  }, [course.id, getArchCourse])

  const renderTitle = () => {
    const words: string[] = [
      course.clientName,
      course.programName,
      course.intakeName,
      course.name,
    ]
    const text = words.join('/')
    return <p className="arch-subheading">{text}</p>
  }

  const onMouseOverTopic = (topic: ArchTopic) => {
    // find the related module by topic id
    const module = modules.find((m) => m.topics.some((t) => t.id === topic.id))
    // find all topic ids in the module
    const topicIds = module?.topics.map((t) => t.id) || []
    setHighlightTopicIds(topicIds)
  }

  const onMouseOutTopic = () => {
    setHighlightTopicIds([])
  }

  const isHighlighted = (topicId: string) => {
    return highlightTopicIds.includes(topicId)
  }

  const getHighlightClass = (topicId: string) => {
    return isHighlighted(topicId) ? 'arch-topic-list-item-highlight' : ''
  }

  const onToggleCollapseModule = (moduleId: string) => {
    if (collapsedModuleIds.includes(moduleId)) {
      setCollapsedModuleIds((prev) => prev.filter((id) => id !== moduleId))
    } else {
      setCollapsedModuleIds((prev) => [...prev, moduleId])
    }
  }

  const isCollapsed = (moduleId: string) => {
    return collapsedModuleIds.includes(moduleId)
  }

  const onDuplicate = (id: string, type: ArchActionType) => {
    Emitter.emit(Events.ShowArchCopy, { type, ids: [id] })
  }

  const renderModule = (module: ArchModule, index: number) => {
    return (
      <React.Fragment key={`${module.id}-${index}`}>
        <li
          key={module.id}
          className="flex flex-row items-center arch-module-list-item"
        >
          <div className="pl-[6px]">
            <span className="whitespace-nowrap">
              Module {index + 1}: {module.name}
            </span>
          </div>

          <div className="grow" />
          <div className="flex flex-row items-center gap-[12px]">
            {isAdmin && (
              <button
                onClick={() => onDuplicate(module.id, ArchActionType.Module)}
              >
                <Svg icon={Icon.Copy} className={IconClass.StrokeBlack} />
              </button>
            )}
            <button onClick={() => onToggleCollapseModule(module.id)}>
              {isCollapsed(module.id) && (
                <Svg icon={Icon.Down} className={IconClass.FillBlack} />
              )}
              {!isCollapsed(module.id) && (
                <Svg icon={Icon.Up} className={IconClass.FillBlack} />
              )}
            </button>
          </div>
        </li>
        {module.topics.length > 0 &&
          !isCollapsed(module.id) &&
          module.topics.map((topic, topicIndex) =>
            renderTopic(topic, index, topicIndex),
          )}
      </React.Fragment>
    )
  }

  const renderQuiz = (quiz: Quiz, index: number) => {
    return (
      <li
        key={quiz.id}
        className="flex flex-row items-center px-[24px] py-[4px] gap-[12px]"
      >
        <div className="min-w-[30%] w-[30%] max-w-[30%] flex flex-row items-center font-[600] font-roboto text-[12px]">
          {quiz.name}
        </div>
        <div className="arch-topic-last-updated-at">
          <span>Last updated:</span>
          <span>{getDateTimeString(quiz.updatedAt)}</span>
        </div>
        <QuizStatusText quiz={quiz} />
      </li>
    )
  }

  const readOnlyMode = (prefix: string, topicId: string): boolean => {
    return !editedModeCells[`${prefix}-${topicId}`]
  }

  const getResultByTopicId = (topicId: string): boolean => {
    // find the result by topic id, pass or fail
    return (
      checkTopicsResult?.results?.find((r) => r.topic.id === topicId)?.result
        .success || false
    )
  }

  const showResultIcon = (topicId: string): boolean => {
    return (
      checkTopicsResult?.results?.find((r) => r.topic.id === topicId) !==
      undefined
    )
  }

  const getResultIcon = (topicId: string): string => {
    const topic = modules.flatMap((m) => m.topics).find((t) => t.id === topicId)
    if (!topic) {
      return placeholderPng
    }
    // compare the updatedAt of the topic with the result
    const resultUpdatedAt = checkTopicsResult?.updatedAt
    if (resultUpdatedAt && topic.updatedAt > resultUpdatedAt) {
      return placeholderPng
    }

    if (!showResultIcon(topicId)) {
      return placeholderPng
    }
    return getResultByTopicId(topicId) ? tickPng : crossPng
  }

  const renderTopic = (
    topic: ArchTopic,
    moduleIndex: number,
    topicIndex: number,
  ) => {
    const topicNumber = `${moduleIndex + 1}.${topicIndex + 1}`
    return (
      <li
        key={topic.id}
        className={`flex flex-row items-center px-[24px] py-[4px] gap-[12px] ${getHighlightClass(
          topic.id,
        )}`}
        onMouseOver={() => onMouseOverTopic(topic)}
        onMouseOut={onMouseOutTopic}
      >
        <div className="flex flex-row min-w-[30%] w-[30%] max-w-[30%] items-center gap-[12px]">
          {checkTopicsResult && checkTopicsResult.results && (
            <img
              src={getResultIcon(topic.id)}
              width="16"
              alt="result"
              data-tooltip-id={`tooltip-topic-result-${topic.id}`}
            />
          )}
          <div className="arch-topic-list-item-title">{topicNumber}</div>
          <div className="grow flex flex-row items-center">
            {topic.link && (
              <a
                className="arch-topic-list-item-link"
                href={topic.link}
                target="_blank"
                rel="noreferrer"
              >
                {topic.name}
              </a>
            )}
            {!topic.link && (
              <TextareaAutosize
                className="w-full arch-read-only-small"
                value={topic.name} // disable editing
                readOnly={true} // disable editing
                onDoubleClick={() => {
                  // start editing
                  setEditedModeCells({
                    ...editedModeCells,
                    [`topic-name-${topic.id}`]: true,
                  })
                }}
                onBlur={
                  // stop editing
                  () => {
                    setEditedModeCells({
                      ...editedModeCells,
                      [`topic-name-${topic.id}`]: false,
                    })
                  }
                }
                onKeyDown={handleKeyUpForTextArea}
                onKeyUp={async (e) => {
                  if (e.key === 'Enter') {
                    const value = e.currentTarget.value
                    if (value === topic.name) {
                      return
                    }
                    resetTimeout(`topic-name-${topic.id}`)
                    await updateTopicName(topic.id, value, true)
                  } else {
                    // auto save by x seconds
                    const value = e.currentTarget.value.trim()
                    if (value !== topic.name) {
                      const timeoutId = setTimeout(async () => {
                        await updateTopicName(topic.id, value)
                      }, timeoutForSave)
                      resetTimeout(`topic-name-${topic.id}`, timeoutId)
                    }
                  }
                }}
                placeholder="Type topic name here"
              />
            )}
          </div>
          {isAdmin && !course.intakeLocked && (
            <button
              className="min-w-[17px]"
              onClick={(e) => {
                Emitter.emit(Events.ShowArchEditTopic, {
                  type: ArchActionType.Topic,
                  topic: topic,
                  // get the position of the edit button
                  position: {
                    x: e.clientX,
                    y: e.clientY,
                  },
                })
              }}
            >
              <Svg icon={Icon.Edit} className={IconClass.FillBlack2} />
            </button>
          )}
        </div>
        <div className="arch-topic-last-updated-at">
          <span>Last updated:</span>
          <span>{getDateTimeString(topic.updatedAt)}</span>
        </div>
        <div className="flex flex-row items-center gap-1 w-full">
          <span className="arch-read-only-small">LO:</span>
          <TextareaAutosize
            className={`w-full mentem-scrollbar-2 ${
              readOnlyMode('learning-outcome', topic.id)
                ? 'arch-read-only-small'
                : 'arch-add-input-small'
            }`}
            defaultValue={topic.learningOutcome}
            placeholder="Type learning outcome here"
            readOnly={readOnlyMode('learning-outcome', topic.id)}
            maxRows={2}
            onDoubleClick={
              // start editing
              () => {
                if (course.intakeLocked) return
                setEditedModeCells({
                  ...editedModeCells,
                  [`learning-outcome-${topic.id}`]: true,
                })
              }
            }
            onBlur={
              // stop editing
              () => {
                setEditedModeCells({
                  ...editedModeCells,
                  [`learning-outcome-${topic.id}`]: false,
                })
              }
            }
            onKeyDown={handleKeyUpForTextArea}
            onKeyUp={async (e) => {
              if (e.key === 'Enter') {
                const value = e.currentTarget.value
                if (value === topic.learningOutcome) {
                  return
                }
                if (value !== topic.learningOutcome) {
                  resetTimeout(`topic-learning-outcome-${topic.id}`)
                  await updateTopicLearningOutcome(
                    topic.id,
                    e.currentTarget.value,
                    true,
                  )
                }
              } else {
                // auto save by x seconds
                const value = e.currentTarget.value.trim()
                if (value !== topic.learningOutcome) {
                  const timeoutId = setTimeout(async () => {
                    await updateTopicLearningOutcome(topic.id, value)
                  }, timeoutForSave)
                  resetTimeout(`topic-learning-outcome-${topic.id}`, timeoutId)
                }
              }
            }}
          />
          {isAdmin && !course.intakeLocked && (
            <button
              className="min-w-[17px] mr-[10%]"
              onClick={() => {
                // toggle editing
                setEditedModeCells({
                  ...editedModeCells,
                  [`learning-outcome-${topic.id}`]:
                    !editedModeCells[`learning-outcome-${topic.id}`],
                })
              }}
            >
              <Svg icon={Icon.Edit} className={IconClass.FillBlack2} />
            </button>
          )}
        </div>
      </li>
    )
  }

  // TextareaAutosize default value is uncontrollable and created once
  const onUpdatedArchTopic = useCallback(
    (data: { topic: ArchTopic }) => {
      const topicId = data.topic.id
      // update local state
      const newModules = [...modules]
      const moduleIndex = newModules.findIndex((m) =>
        m.topics.some((t) => t.id === topicId),
      )
      if (moduleIndex === -1) {
        console.log('Module not found')
        return
      }
      const module = newModules[moduleIndex]
      const topicIndex = module.topics.findIndex((t) => t.id === topicId)
      if (topicIndex === -1) {
        console.log('Topic not found')
        return
      }
      const newTopics = [...module.topics]
      // console.log('newTopics', newTopics)
      newTopics[topicIndex] = data.topic
      module.topics = newTopics
      // console.log('newModules', newModules)
      setModules(newModules)
    },
    [modules],
  )

  const onExportExcel = async () => {
    try {
      const res = await exportArchExcel(
        course.programId,
        course.intakeId,
        course.id,
      )
      const url = window.URL.createObjectURL(new Blob([res]))
      const link = document.createElement('a')
      const filename = getExportFileNameForCourse(course.name)
      link.href = url
      link.setAttribute('download', filename)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      console.log('Error exporting excel', error)
    }
  }

  const onCheckTopics = async () => {
    try {
      setIsCheckingTopics(true)
      const res = await checkTopics(course.id)
      setResult(res)
      setShowResult(true)
      // update check topics result if exists
      setCheckTopicsResult(res)
    } catch (error) {
      ToastUtil.error('Check topics failed')
      console.error(error)
    } finally {
      setIsCheckingTopics(false)
    }
  }

  const onCloseResult = () => {
    setShowResult(false)
    setResult(null)
  }

  const getFailedMessage = (field: string) => {
    const domain = process.env.REACT_APP_PUBLISH_URL
    switch (field) {
      case 'title':
        return 'Topic title wording does not match architecture'
      case 'learningOutcomes':
        return 'Topic LO wording does not match architecture'
      case 'module':
        return 'Topic module title wording does not match architecture'
      case 'domain':
        return `Topic link is not a ${domain} page`
      default:
        return ''
    }
  }

  useEffect(() => {
    if (isLogged) {
      fetchCourse()
      fetchCheckTopicsResults()
    }
  }, [fetchCheckTopicsResults, fetchCourse, isLogged])

  useEffect(() => {
    Emitter.on(Events.UpdatedArchTopic, onUpdatedArchTopic)
    return () => {
      Emitter.off(Events.UpdatedArchTopic, onUpdatedArchTopic)
    }
  }, [onUpdatedArchTopic])

  return (
    <>
      <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex items-center justify-center">
        <div className="h-3/4 w-3/4 flex flex-col bg-white shadow-xl rounded-[15px] gap-[12px] p-[24px]">
          <div className="flex flex-row">
            <div className="grow" />
            <div className="self-end cursor-pointer" onClick={onClose}>
              <Svg icon={Icon.Close} className={IconClass.FillBlack2} />
            </div>
          </div>
          <div className="flex flex-row">
            {course.intakeLocked && <Svg icon={Icon.LockLarge} />}
            <div className="w-[12px]" />
            {renderTitle()}
            <div className="grow" />
            <button
              className={StyleUtil.buttonSecondary}
              onClick={onCheckTopics}
            >
              {isCheckingTopics && <ButtonSpinner />}
              {!isCheckingTopics && 'Check topics'}
            </button>
            <div className="w-2" />
            <button className={StyleUtil.buttonPrimary} onClick={onExportExcel}>
              Export Excel
            </button>
          </div>
          {isFetchingCourse && <Spinner />}
          {!isFetchingCourse && modules.length === 0 && (
            <div className="flex flex-col items-center justify-center">
              <p className="text-center">No modules found</p>
            </div>
          )}
          {!isFetchingCourse && modules.length > 0 && (
            <div className="overflow-y-auto mentem-scrollbar-2">
              <ul className="divide-slate-200 divide-y">
                {modules.map((module, moduleIndex) =>
                  renderModule(module, moduleIndex),
                )}
              </ul>
              {!isFetchingCourse && quizzes.length > 0 && (
                <>
                  <div className="flex flex-row w-full arch-module-list-item">
                    <div className="pl-[6px]">Tests</div>
                    <div className="grow" />
                    <div className="flex flex-row items-center gap-[12px]">
                      <button
                        onClick={() =>
                          setIsCollapsedAssessments(!isCollapsedAssessments)
                        }
                      >
                        {isCollapsedAssessments ? (
                          <Svg
                            icon={Icon.Down}
                            className={IconClass.FillBlack}
                          />
                        ) : (
                          <Svg icon={Icon.Up} className={IconClass.FillBlack} />
                        )}
                      </button>
                    </div>
                  </div>
                  {!isCollapsedAssessments && (
                    <ul className="divide-slate-200 divide-y">
                      {quizzes.map((quiz, quizIndex) =>
                        renderQuiz(quiz, quizIndex),
                      )}
                    </ul>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {showResult && result && (
        <WidgetArchCheckTopicsResult result={result} onClose={onCloseResult} />
      )}
      {checkTopicsResult &&
        checkTopicsResult.results &&
        checkTopicsResult.results.map((r) => {
          return (
            <Tooltip
              key={`tooltip-topic-result-${r.topic.id}`}
              id={`tooltip-topic-result-${r.topic.id}`}
              className="mentem-tooltip-large z-[9999]"
              style={tooltipStyle}
              place="right"
              noArrow={true}
            >
              {r.result.success ? (
                'Title, LOs and link match architecture'
              ) : (
                <div>
                  <p>
                    <b>Failed:</b>
                  </p>
                  {r.result.validations
                    ?.filter((v) => !v.matched)
                    ?.map((v, index) => (
                      <p key={index}>
                        <>
                          <p>
                            <b>{getFailedMessage(v.field)}</b>
                          </p>
                        </>
                      </p>
                    ))}
                  {r.result.message && (
                    <p>
                      <b>{r.result.message}</b>
                    </p>
                  )}
                </div>
              )}
            </Tooltip>
          )
        })}
    </>
  )
}
