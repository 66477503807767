import { Quiz, QuizStatus } from 'apis/entities/quiz.entity'

export const getStatusStyle = (quiz: Quiz): string => {
  const { approved, status, deletedAt } = quiz
  if (approved) return 'quiz-status-approved'
  if (deletedAt) return 'quiz-status-deleted'
  switch (status) {
    case QuizStatus.Draft:
      return 'quiz-status-draft'
    case QuizStatus.Reviewed:
      return 'quiz-status-reviewed'
    case QuizStatus.Sent:
      return 'quiz-status-sent'
    default:
      return ''
  }
}

export const getStatusText = (quiz: Quiz): string => {
  const { approved, status, deletedAt } = quiz
  // console.log('quiz', quiz)
  if (approved) return 'Approved'
  if (deletedAt) return 'Deleted'
  switch (status) {
    case QuizStatus.Draft:
      return 'Draft'
    case QuizStatus.Reviewed:
      return 'Reviewed'
    case QuizStatus.Sent:
      return 'Sent on'
    default:
      return status
  }
}
