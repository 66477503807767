import { useState } from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { ToastUtil } from 'utils/ToastUtil'
import closeSvg from '../images/close2.svg'
import Spinner from './Spinner'
import React from 'react'
import { Quiz } from 'apis/entities/quiz.entity'
import { ValidationUtil } from 'utils/ValidationUtil'
import { useQuizApi } from 'providers/QuizApiProvider'

interface Props {
  quiz: Quiz
  onClose: (success: boolean) => void
}

export default function WidgetAddReviewers({
  quiz,
  onClose,
}: Props): JSX.Element {
  const { addQuizReviewers } = useQuizApi()
  const refInputReviewers = React.createRef<HTMLTextAreaElement>()
  const [isLoading, setIsLoading] = useState(false)

  const onClickConfirm = async () => {
    try {
      const tmp = refInputReviewers.current?.value ?? ''
      const emails = tmp.split(',').map((email) => email.trim())
      if (tmp.length === 0 || emails.length === 0) {
        ToastUtil.warning('Please enter at least one email')
        return
      }
      // validate all emails
      for (const email of emails) {
        if (!ValidationUtil.isEmail(email)) {
          ToastUtil.warning(`Invalid email: ${email}`)
          return
        }
      }
      setIsLoading(true)
      await addQuizReviewers(quiz.id, emails)
      ToastUtil.success('Email(s) sent successfully')
      onClose(true)
    } catch (error) {
      console.log(error)
      ToastUtil.error('Failed to send email(s)')
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center">
      <div className="h-auto w-1/2 flex flex-col bg-white shadow-xl rounded-[12px] p-[12px] gap-[12px]">
        <img
          src={closeSvg}
          className="self-end cursor-pointer"
          alt="close"
          onClick={() => onClose(false)}
        />
        {quiz.reviewers.length > 0 && (
          <p className="quiz-email-message px-[12px]">
            We have populated this list with the emails you sent to previously.
            Please make sure you have taken in all feedback before sending for
            review again.
          </p>
        )}
        <div className="flex flex-col px-[12px]">
          <textarea
            cols={40}
            rows={4}
            placeholder="Type email address here, seperate email(s) by comma"
            className="quiz-email-input"
            ref={refInputReviewers}
            defaultValue={quiz.reviewers
              .filter((reviewer) => reviewer.version === quiz.version)
              .map((reviewer) => reviewer.email)
              .join(',')}
          />
        </div>
        {isLoading && <Spinner />}
        {!isLoading && (
          <div className="flex items-center justify-center">
            <button
              type="button"
              className={StyleUtil.buttonPrimary}
              onClick={() => onClickConfirm()}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  )
}
