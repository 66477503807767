import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from 'react'

export type DropDownMenuRefType = {
  setSelectedItemId: (id: string) => void
}

export type DropDownItem = {
  id: string
  name: string
  value?: any
  isLabel?: boolean
  isShowAllOptions?: boolean
  default?: boolean
}

type Props = {
  items: DropDownItem[]
  onSelected: (item: DropDownItem) => void
  value?: string
  border?: boolean
  className?: string
  style?: React.CSSProperties
  // todo: delete below variables in future
  customHACK?: boolean
  locked?: boolean
}

const DropDownMenu = forwardRef(
  (
    {
      items,
      onSelected,
      value,
      border,
      customHACK,
      className,
      style,
      locked,
    }: Props,
    ref,
  ) => {
    const internalRef = useRef<HTMLDivElement>(null)
    const [isShownDropDown, setIsShownDropDown] = useState<boolean>(false)
    const [selectedItem, setSelectedItem] = useState<DropDownItem>()

    const [htmlWidth, setHtmlWidth] = useState<number>(0)
    const htmlRef = useRef<HTMLDivElement>(null)
    const borderStyle =
      'border-[1px] border-[#A3A3A9] rounded-[3px] px-[12px] py-[6px] w-full bg-white'

    let customDropDownListItemClassName: string | null = null
    let customDropDownClassName: string | null = null
    let customContainerClassName: string | null = null
    if (customHACK) {
      customDropDownListItemClassName =
        'mentem-dropdown-button p-[6px] cursor-pointer bg-white hover:bg-primary hover:text-white whitespace-nowrap text-center'
      customDropDownClassName =
        'dropdown-content z-[1] shadow bg-base-100 max-h-[60vh] overflow-y-auto min-w-[188px]'
      customContainerClassName = 'min-h-[36px] min-w-[188px] px-3'
    }

    useEffect(() => {
      if (htmlRef.current) {
        setHtmlWidth((prevWidth) => {
          const currWidth = htmlRef.current?.offsetWidth || 0
          // console.log(currWidth > prevWidth ? currWidth : prevWidth)
          return currWidth > prevWidth ? currWidth : prevWidth
        })
      }
    }, [selectedItem])
    const getItemName = () => {
      if (selectedItem) {
        // check if selected item exists in the items list, otherwise return the first item
        const tmpSelectedItem = items.find(
          (item) => item.id === selectedItem.id,
        )
        if (!tmpSelectedItem) {
          setSelectedItem(items[0])
          return items[0].name
        }

        // Check if the selected item is marked with defaultValue: true
        if (selectedItem.isShowAllOptions) {
          // Find the item marked as a label and return its name
          const labelItem = items.find((item) => item.isLabel)
          return labelItem ? labelItem.name : ''
        }
        // For other items, return their name as usual
        return selectedItem.name
      }

      return items.length > 0 ? items[0].name : ''
    }

    const onSelectItem = async (id: string) => {
      const item = items.find((item) => id === item.id)
      if (item) {
        setSelectedItem(item)
        onSelected(item)
      }
      setIsShownDropDown(false)
    }

    const getDropDownClassName = () => {
      return isShownDropDown
        ? 'dropdown-content z-[1] p-2 shadow bg-base-100 rounded max-h-[60vh] overflow-y-auto min-w-[max-content] w-full'
        : 'hidden'
    }

    const getDropDownListItemClassName = () => {
      // single line
      // return  'mentem-dropdown-button p-1 cursor-pointer bg-white hover:bg-primary hover:text-white whitespace-nowrap text-center',
      // multi line
      return 'mentem-dropdown-button p-1 cursor-pointer bg-white hover:bg-primary hover:text-white text-center w-full'
    }

    const hasItem = (): boolean => {
      // no need to show dropdown if there is only one non-label item and selected item is not a label
      if (!selectedItem) {
        if (items.length === 1 && items[0].isLabel) {
          return false
        }
      } else {
        if (items.length === 1) {
          return false
        } else if (
          items.length === 2 &&
          items[0].isLabel &&
          !selectedItem.isLabel
        ) {
          return false
        }
      }
      return true
    }

    const onMouseOverDropDown = () => {
      // console.log(items, selectedItem)
      // skip if the dropdown is locked
      if (locked) {
        setIsShownDropDown(false)
      } else {
        setIsShownDropDown(hasItem())
      }
    }

    // if the items are not empty and the first item is not a label, set the first item as the selected item
    useEffect(() => {
      if (value) {
        const selectedItemBasedOnValue = items.find(
          (item) => item.value === value,
        )
        if (selectedItemBasedOnValue) {
          setSelectedItem(selectedItemBasedOnValue)
          return
        }
      }

      // find default item
      const defaultItem = items.find((item) => item.default)
      if (defaultItem) {
        setSelectedItem(defaultItem)
        return
      }

      if (items.length > 0 && !items[0].isLabel) {
        setSelectedItem(items[0])
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
      // update selected item if it is changed
      if (selectedItem) {
        const item = items.find((item) => selectedItem.id === item.id)
        if (item && selectedItem.name !== item.name) {
          // console.log(`item ${item}`)
          setSelectedItem(item)
        }
      }
    }, [items, selectedItem])

    useImperativeHandle(ref, () => ({
      setSelectedItemId: (id: string) => {
        // console.log('setSelectedItemId', id)
        const item = items.find((item) => id === item.id)
        if (item) {
          setSelectedItem(item)
        } else if (items.length > 0) {
          setSelectedItem(items[0])
        }
      },
    }))

    return (
      <div
        ref={internalRef}
        tabIndex={-1}
        className={`dropdown dropdown-hover align-middle ${className || ''}`}
        onMouseOver={onMouseOverDropDown}
        style={{ display: 'grid', justifyItems: 'center', ...(style || {}) }}
      >
        <div
          ref={htmlRef}
          className={`flex items-center justify-center ${
            border ? borderStyle : ''
          } ${customContainerClassName || ''}`}
          style={{
            ...(customHACK && {
              border: '1px solid #F0F0F0',
              borderRadius: '3px 3px 0 0',
              paddingRight: 7,
              cursor: 'pointer',

              width: '100%',
              // ...(htmlWidth && {
              //   width: `${htmlWidth}px`,
              //   minWidth: `${htmlWidth}px`,
              // }),
            }),
          }}
        >
          <div className="grow" />
          <label
            tabIndex={0}
            className="mentem-dropdown-button mr-4 max-w-[400px] whitespace-normal text-center"
            style={{
              ...(customHACK && {
                whiteSpace: 'nowrap',
              }),
            }}
          >
            {getItemName()}
          </label>
          <div className="grow" />
          {!locked && (
            <svg
              width="14"
              height="9"
              viewBox="0 0 14 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              style={{
                ...(customHACK && {
                  minWidth: '14px',
                  marginTop: '2px',
                }),
              }}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.292893 0.511643C0.653377 0.151159 1.22061 0.12343 1.6129 0.428455L1.70711 0.511643L7 5.80375L12.2929 0.511643C12.6534 0.151159 13.2206 0.12343 13.6129 0.428455L13.7071 0.511643C14.0676 0.872127 14.0953 1.43936 13.7903 1.83165L13.7071 1.92586L7.70711 7.92586C7.34662 8.28634 6.77939 8.31407 6.3871 8.00905L6.29289 7.92586L0.292893 1.92586C-0.0976311 1.53533 -0.0976311 0.902168 0.292893 0.511643Z"
                fill="#51597B"
              />
            </svg>
          )}
        </div>
        <div
          style={{
            display: 'grid',
            justifyItems: 'center',
          }}
        >
          <ul
            tabIndex={0}
            className={customDropDownClassName || getDropDownClassName()}
            {...(customHACK && {
              style: {
                borderRadius: '0 0 6px 6px',
                boxShadow:
                  '0px 0px 1px 0px rgba(48, 49, 51, 0.05), 0px 16px 24px 0px rgba(48, 49, 51, 0.10)',
                ...(htmlWidth && {
                  width: `${htmlWidth}px`,
                  minWidth: `${htmlWidth}px`,
                }),
              },
            })}
          >
            {items
              .filter((item) => !item.isLabel && item.id !== selectedItem?.id)
              .map((item) => {
                return (
                  <li
                    key={item.id}
                    className={
                      customDropDownListItemClassName ||
                      getDropDownListItemClassName()
                    }
                    onClick={() => onSelectItem(item.id)}
                  >
                    {item.name}
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    )
  },
)

export default DropDownMenu
