import EventEmitter from 'eventemitter3'
const eventEmitter = new EventEmitter()
const Emitter = {
  on: (event: string, fn: any) => eventEmitter.on(event, fn),
  once: (event: string, fn: any) => eventEmitter.once(event, fn),
  off: (event: string, fn: any) => eventEmitter.off(event, fn),
  emit: (event: string, payload: any) => eventEmitter.emit(event, payload),
}
Object.freeze(Emitter)
export default Emitter

export enum Events {
  GenerateNewQuestion = 'generate-new-question',
  RegenerateQuestion = 'regenerate-question',
  // Architectures
  MoveArchProgram = 'move-arch-program',
  MovedArchProgram = 'moved-arch-program',
  ShowArchCourseDetails = 'show-arch-course-details',
  ShowArchCopy = 'show-arch-copy',
  ShowArchEditTopic = 'show-arch-edit-topic',
  UpdatedArchTopic = 'updated-arch-topic',
  UploadArchExcel = 'upload-arch-excel',
  ReloadArchClient = 'reload-arch-client',
  RenamedArchClient = 'renamed-arch-client',
  CopiedArchObject = 'copied-arch-object',
  ArchSaveAndExit = 'arch-save-and-exit',
  ShowUnassignedAssignments = 'show-unassigned-assignments',
  AddedUnassignedAssignments = 'added-unassigned-assignments',
  // Documents / Topics Catalogue
  ReloadDocuments = 'reload-documents',
  // Quizzes
  ShowQuizQuestionEditTopic = 'show-quiz-question-edit-topic',
  UpdatedQuizQuestionTopic = 'updated-quiz-question-topic',
  // deprecated
  ShowQuizQuestionEditTopicLink = 'show-quiz-question-edit-topic-link',
  UpdatedQuizQuestionTopicLink = 'updated-quiz-question-topic-link',
}
