import { useRef, useState } from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { ToastUtil } from 'utils/ToastUtil'
import closeSvg from '../images/close2.svg'
import excelSvg from '../images/excel.svg'
import Spinner from './Spinner'
import { FileObject } from 'apis/entities/file.entity'
import { useAuth } from 'providers/AuthProvider'
import { useQuizApi } from 'providers/QuizApiProvider'

interface Props {
  courseId: string
  onCreated: (quizId: string) => void
}

export default function QuizUpload({
  courseId,
  onCreated,
}: Props): JSX.Element {
  const { getAuth0UserDetails } = useAuth()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const refInput = useRef<HTMLInputElement | null>(null)
  const refInputDesc = useRef<HTMLInputElement | null>(null)
  const [files, setFiles] = useState<FileObject[]>([])
  const refOverlay = useRef<HTMLDivElement | null>(null)
  const { uploadFileForQuiz } = useQuizApi()
  const refIsUploaded = useRef<boolean>(false)
  // for drag and drop
  let counter = 0

  const addFiles = (newFiles: File[]) => {
    const tmpFiles = newFiles.map((file) => {
      return { objectURL: URL.createObjectURL(file), file: file }
    })
    setFiles(files.concat(tmpFiles))
  }

  const hasFiles = (e: React.DragEvent<HTMLDivElement>) => {
    const types = e.dataTransfer.types
    return types.indexOf('Files') > -1
  }

  const onSelect = () => {
    refInput.current?.click()
  }

  const onInputFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files || []

    // check the file extension is xls or xlsx
    for (const file of files) {
      const ext = file.name.split('.').pop()
      if (ext !== 'xls' && ext !== 'xlsx') {
        ToastUtil.warning('Please select .xls or .xlsx file')
        return
      }
    }

    const newFiles: File[] = []
    for (const file of files) {
      newFiles.push(file)
    }
    addFiles(newFiles)
    // reset selected files
    refInput.current!.value = ''
  }

  const onDropHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    // console.log(e.dataTransfer.files)
    const newFiles: File[] = []
    for (const file of e.dataTransfer.files) {
      newFiles.push(file)
      refOverlay.current?.classList.remove('draggedover')
      counter = 0
    }
    addFiles(newFiles)
  }

  const onDragEnterHandler = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    if (!hasFiles(e)) {
      return
    }
    ++counter && refOverlay.current?.classList.add('draggedover')
  }

  const onDragLeaveHandler = (e: React.DragEvent<HTMLDivElement>) => {
    1 > --counter && refOverlay.current?.classList.remove('draggedover')
  }

  const onDragOverHandler = (e: React.DragEvent<HTMLDivElement>) => {
    if (hasFiles(e)) {
      e.stopPropagation()
      e.preventDefault()
    }
  }

  const onRemoveFile = (index: number) => {
    const tmpFiles = [...files]
    tmpFiles.splice(index, 1)
    setFiles(tmpFiles)
  }

  const onUpload = async () => {
    if (files.length === 0) {
      ToastUtil.warning('Please select file')
      return
    }

    setIsLoading(true)
    for (let i = 0; i < files.length; i++) {
      try {
        const file = files[i].file
        let formData = new FormData()
        const user = getAuth0UserDetails()
        formData.append('file', file)
        const description = refInputDesc.current?.value
        if (description) {
          formData.append('description', description)
        }
        formData.append('auth0Id', user?.id || '')
        formData.append('username', user?.username || '')
        formData.append('firstName', user?.firstName || '')
        formData.append('email', user?.email || '')
        formData.append('courseId', courseId)

        const result = await uploadFileForQuiz(formData)
        console.log(result)
        ToastUtil.success('Uploaded successfully')
        refIsUploaded.current = true
        onCreated(result.id)
      } catch (err) {
        console.error(err)
        ToastUtil.error('Upload failed')
      }
    }
    setIsLoading(false)
    // reset files
    setFiles([])
  }

  return (
    <div className="flex flex-row items-center justify-center">
      <div
        className="h-auto w-1/2 flex flex-col bg-white shadow-xl rounded-[15px] gap-[36px] p-[36px]"
        onDrop={onDropHandler}
        onDragOver={onDragOverHandler}
        onDragLeave={onDragLeaveHandler}
        onDragEnter={onDragEnterHandler}
      >
        <div className="flex flex-col px-[36px]">
          <div className="border border-1 border-gray-400 rounded-[6px] py-8 flex flex-col justify-center items-center min-h-[270px]">
            {files.length > 0 && (
              <div className="flex flex-col items-center gap-[12px]">
                <img src={excelSvg} alt="spreadsheet" />
                <p className="text-center">{files[0].file.name}</p>
                <img
                  src={closeSvg}
                  className="cursor-pointer"
                  alt="remove"
                  onClick={() => onRemoveFile(0)}
                />
              </div>
            )}
            {files.length === 0 && (
              <>
                <p className="quiz-upload-placeholder">
                  Drag spreadsheet here to upload
                </p>
                <input
                  data-testid="hidden-input"
                  id="hidden-input"
                  title="hidden-input"
                  type="file"
                  multiple={false}
                  className="hidden"
                  ref={refInput}
                  onChange={onInputFiles}
                />
                <button
                  data-testid="btn-select"
                  type="button"
                  id="btn-select"
                  onClick={() => onSelect()}
                  className={`${StyleUtil.buttonPrimary} mt-8 mb-8`}
                >
                  <span className="pl-2 pr-2">Select file</span>
                </button>
              </>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center">
          {isLoading && <Spinner />}
          {!isLoading && (
            <button
              data-testid="btn-upload"
              type="button"
              id="btn-upload"
              onClick={onUpload}
              disabled={isLoading}
              className={StyleUtil.buttonPrimary}
            >
              <span className="pl-2 pr-2">Upload</span>
            </button>
          )}
        </div>
      </div>
    </div>
  )
}
