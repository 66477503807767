import { useCallback, useEffect, useRef, useState } from 'react'
import { ToastUtil } from 'utils/ToastUtil'
import { useArchitectureApi } from 'providers/ArchitectureApiProvider'
import {
  ArchClient,
  ArchCourse,
  ArchIntake,
  ArchModule,
  ArchProgram,
} from 'apis/entities/architecture.entity'
import Spinner from './Spinner'
import Svg, { Icon, IconClass } from 'components/Svg'
import DropDownMenu, { DropDownItem, DropDownMenuRefType } from './DropDownMenu'
import Emitter, { Events } from 'core/emitter'
import { StyleUtil } from 'utils/StyleUtil'
import ButtonSpinner from './ButtonSpinner'

enum CreateType {
  Program = 'program',
  Intake = 'intake',
  Course = 'course',
  Module = 'module',
}

const ClientOptionLabel = {
  id: '0',
  name: 'Choose the client you’d like copy to',
  value: '0',
  isLabel: true,
}

const ProgramOptionLabel = {
  id: '0',
  name: 'Choose the program you’d like copy to',
  value: '0',
  isLabel: true,
}

const IntakeOptionLabel = {
  id: '0',
  name: 'Choose the intake you’d like copy to',
  value: '0',
  isLabel: true,
}

const CourseOptionLabel = {
  id: '0',
  name: 'Choose the course you’d like copy to',
  value: '0',
  isLabel: true,
}

const ModuleOptionLabel = {
  id: '0',
  name: 'Choose the module you’d like copy to',
  value: '0',
  isLabel: true,
}

interface Props {
  programId?: string
  intakeId?: string
  courseIds?: string[]
  moduleIds?: string[]
  topicIds?: string[]
  documentIds?: string[] // copy from topic catalogue
  quizIds?: string[]
  onClose: () => void
}

export default function WidgetArchCopy({
  programId,
  intakeId,
  courseIds,
  moduleIds,
  topicIds,
  documentIds,
  quizIds,
  onClose,
}: Props): JSX.Element {
  const {
    getArchClients,
    getArchClient,
    cloneArchProgram,
    cloneArchIntake,
    cloneArchCourse,
    cloneArchModule,
    cloneArchTopic,
    copyDocumentToArchTopic,
    createArchProgram,
    createArchIntake,
    createArchCourse,
    createArchModule,
    cloneQuizToCourse,
  } = useArchitectureApi()
  const [isFetchingClients, setIsFetchingClients] = useState(false)
  const [isFetchingClient, setIsFetchingClient] = useState(false)
  const [isCopying, setIsCopying] = useState(false)
  const [clients, setClients] = useState<ArchClient[]>([])

  const selectedClient = useRef<ArchClient | null>(null)
  const selectedProgram = useRef<ArchProgram | null>(null)
  const selectedIntake = useRef<ArchIntake | null>(null)
  const selectedCourse = useRef<ArchCourse | null>(null)
  const selectedModule = useRef<ArchModule | null>(null)

  const [clientOptions, setClientOptions] = useState<any>([ClientOptionLabel])
  const [programOptions, setProgramOptions] = useState<any>([
    ProgramOptionLabel,
  ])
  const [intakeOptions, setIntakeOptions] = useState<any>([IntakeOptionLabel])
  const [courseOptions, setCourseOptions] = useState<any>([CourseOptionLabel])
  const [moduleOptions, setModuleOptions] = useState<any>([ModuleOptionLabel])
  const [showCreate, setShowCreate] = useState(false)
  const [createType, setCreateType] = useState<CreateType | null>(null)
  const [isCreating, setIsCreating] = useState(false)
  const refCreateInput = useRef<HTMLInputElement | null>(null)

  const refDropDownMenuPrograms = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuIntakes = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuCourses = useRef<DropDownMenuRefType>(null)
  const refDropDownMenuModules = useRef<DropDownMenuRefType>(null)

  const copyProgram = useCallback(async () => {
    if (!selectedClient.current || !programId) {
      return
    }
    try {
      setIsCopying(true)
      const clonedProgram = await cloneArchProgram(
        programId,
        selectedClient.current.id,
      )
      Emitter.emit(Events.CopiedArchObject, {
        clientId: selectedClient.current.id,
        programId: clonedProgram.id,
      })
      ToastUtil.success('Program copied successfully')
      onClose()
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to copy program')
    } finally {
      setIsCopying(false)
    }
  }, [cloneArchProgram, onClose, programId, selectedClient])

  const copyIntake = useCallback(async () => {
    if (!selectedProgram.current || !intakeId) {
      return
    }
    try {
      setIsCopying(true)
      const clonedIntake = await cloneArchIntake(
        intakeId,
        selectedProgram.current.id,
      )
      Emitter.emit(Events.CopiedArchObject, {
        clientId: selectedClient.current?.id,
        intakeId: clonedIntake.id,
      })
      ToastUtil.success('Intake copied successfully')
      onClose()
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to copy intake')
    } finally {
      setIsCopying(false)
    }
  }, [cloneArchIntake, intakeId, onClose, selectedProgram])

  const copyCourses = useCallback(async () => {
    if (!selectedIntake.current || !courseIds) {
      return
    }
    try {
      setIsCopying(true)
      const clonedCoruseIds: string[] = []
      for (const courseId of courseIds) {
        const clonedCourse = await cloneArchCourse(
          courseId,
          selectedIntake.current.id,
        )
        clonedCoruseIds.push(clonedCourse.id)
      }
      Emitter.emit(Events.CopiedArchObject, {
        clientId: selectedClient.current?.id,
        courseIds: clonedCoruseIds,
      })
      ToastUtil.success('Courses copied successfully')
      onClose()
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to copy courses')
    } finally {
      setIsCopying(false)
    }
  }, [cloneArchCourse, courseIds, onClose, selectedIntake])

  const copyModules = useCallback(async () => {
    if (!selectedCourse.current || !moduleIds) {
      return
    }
    try {
      setIsCopying(true)
      const clonedModuleIds: string[] = []
      for (const moduleId of moduleIds) {
        const clonedModule = await cloneArchModule(
          moduleId,
          selectedCourse.current.id,
        )
        clonedModuleIds.push(clonedModule.id)
      }
      Emitter.emit(Events.CopiedArchObject, {
        clientId: selectedClient.current?.id,
        moduleIds: clonedModuleIds,
      })
      ToastUtil.success('Modules copied successfully')
      onClose()
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to copy modules')
    } finally {
      setIsCopying(false)
    }
  }, [cloneArchModule, moduleIds, onClose, selectedCourse])

  const copyQuizzes = useCallback(async () => {
    if (!selectedCourse.current || !quizIds) {
      return
    }
    try {
      setIsCopying(true)
      for (const quizId of quizIds) {
        await cloneQuizToCourse(quizId, selectedCourse.current.id)
      }
      ToastUtil.success('Quizzes copied successfully')
      onClose()
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to copy quizzes')
    } finally {
      setIsCopying(false)
    }
  }, [cloneQuizToCourse, onClose, quizIds, selectedCourse])

  const copyTopics = useCallback(async () => {
    if (!selectedModule.current || !topicIds) {
      return
    }
    try {
      setIsCopying(true)
      const clonedTopicIds: string[] = []
      for (const topicId of topicIds) {
        const clonedTopic = await cloneArchTopic(
          topicId,
          selectedModule.current.id,
        )
        clonedTopicIds.push(clonedTopic.id)
      }
      Emitter.emit(Events.CopiedArchObject, {
        clientId: selectedClient.current?.id,
        topicIds: clonedTopicIds,
      })
      ToastUtil.success('Topics copied successfully')
      onClose()
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to copy topics')
    } finally {
      setIsCopying(false)
    }
  }, [cloneArchTopic, onClose, selectedModule, topicIds])

  const fetchClient = useCallback(
    async (clientId: string) => {
      try {
        setIsFetchingClient(true)
        const client = await getArchClient(clientId)
        selectedClient.current = client
        // filter programs
        const programs = client.programs.map((program) => ({
          id: program.id,
          name: program.name,
          value: program.id,
        }))
        setProgramOptions([ProgramOptionLabel, ...programs])
      } catch (error) {
        console.error(error)
        ToastUtil.error('Failed to fetch client')
      } finally {
        setIsFetchingClient(false)
      }
    },
    [getArchClient],
  )

  const fetchClients = useCallback(async () => {
    try {
      setIsFetchingClients(true)
      const clients = await getArchClients()
      // console.log(clients)
      setClients(clients)

      const allOptions = clients.map((client) => ({
        id: client.id,
        name: client.name,
        value: client.id,
      }))

      setClientOptions([ClientOptionLabel, ...allOptions])
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to fetch clients')
    } finally {
      setIsFetchingClients(false)
    }
  }, [getArchClients])

  const onSelectClient = async (item: DropDownItem) => {
    const id = item.value
    if (id === '0') {
      return
    }
    // find client
    const client = clients.find((c) => c.id === id)
    if (!client) {
      return
    }
    selectedClient.current = client

    // reset all selected
    setProgramOptions([ProgramOptionLabel])
    setIntakeOptions([IntakeOptionLabel])
    setCourseOptions([CourseOptionLabel])
    setModuleOptions([ModuleOptionLabel])
    selectedProgram.current = null
    selectedIntake.current = null
    selectedCourse.current = null
    selectedModule.current = null

    await triggerCopy()

    // get client for whole architecture if cloning other than program
    if (
      intakeId ||
      courseIds ||
      moduleIds ||
      topicIds ||
      documentIds ||
      quizIds
    ) {
      await fetchClient(id)
    }
  }

  const onSelectProgram = (item: DropDownItem) => {
    const id = item.value
    if (id === '0') {
      return
    }
    // filter intakes
    const program = selectedClient?.current?.programs?.find((p) => p.id === id)
    if (!program) {
      return
    }
    selectedProgram.current = program
    const intakes = program?.intakes?.map((intake) => ({
      id: intake.id,
      name: intake.name,
      value: intake.id,
    }))
    // reset all selected
    setIntakeOptions([IntakeOptionLabel, ...intakes])
    setCourseOptions([CourseOptionLabel])
    setModuleOptions([ModuleOptionLabel])
    selectedIntake.current = null
    selectedCourse.current = null
    selectedModule.current = null

    triggerCopy()
  }

  const onSelectIntake = (item: DropDownItem) => {
    const id = item.value
    if (id === '0') {
      return
    }
    // filter courses
    const intake = selectedProgram?.current?.intakes?.find((i) => i.id === id)
    if (!intake) {
      return
    }
    selectedIntake.current = intake
    const courses = intake?.courses?.map((course) => ({
      id: course.id,
      name: course.name,
      value: course.id,
    }))
    // reset all selected
    setCourseOptions([CourseOptionLabel, ...courses])
    setModuleOptions([ModuleOptionLabel])
    selectedCourse.current = null
    selectedModule.current = null

    triggerCopy()
  }

  const onSelectCourse = (item: DropDownItem) => {
    const id = item.value
    if (id === '0') {
      return
    }
    // filter modules
    const course = selectedIntake?.current?.courses?.find((c) => c.id === id)
    if (!course) {
      return
    }
    selectedCourse.current = course
    const modules = course?.modules?.map((module) => ({
      id: module.id,
      name: module.name,
      value: module.id,
    }))
    // reset all selected
    setModuleOptions([ModuleOptionLabel, ...modules])
    selectedModule.current = null

    triggerCopy()
  }

  const onSelectModule = (item: DropDownItem) => {
    const id = item.value
    if (id === '0') {
      return
    }
    const module = selectedCourse?.current?.modules?.find((m) => m.id === id)
    if (!module) {
      return
    }
    selectedModule.current = module

    triggerCopy()
  }

  const emitReloadClient = (
    clientId: string,
    updateCourseTable: boolean = false,
  ) => {
    Emitter.emit(Events.ReloadArchClient, {
      clientId: clientId,
      updateCourseTable: updateCourseTable,
    })
  }

  const createTopicsFromDocuments = async () => {
    // console.log('createTopicsFromDocuments', documentIds)
    if (!selectedModule.current || !documentIds) {
      return
    }
    try {
      setIsCopying(true)
      for (const documentId of documentIds) {
        const newTopic = await copyDocumentToArchTopic(
          documentId,
          selectedModule.current.id,
        )
        console.log('newTopic', newTopic)
      }
      Emitter.emit(Events.ReloadDocuments, {
        documentIds: documentIds,
        isLocked: true,
      })
      ToastUtil.success('Topic copied successfully')
      onClose()
    } catch (error) {
      console.error(error)
      ToastUtil.error((error as any).message || 'Failed to copy topic')
    } finally {
      setIsCopying(false)
    }
  }

  const triggerCopy = async () => {
    // console.log('triggerCopy')
    if (
      topicIds &&
      selectedModule.current &&
      selectedCourse.current &&
      selectedIntake.current &&
      selectedProgram.current &&
      selectedClient.current
    ) {
      // console.log('copy topics')
      await copyTopics()
    } else if (
      documentIds &&
      selectedModule.current &&
      selectedCourse.current &&
      selectedIntake.current &&
      selectedProgram.current &&
      selectedClient.current
    ) {
      await createTopicsFromDocuments()
    } else if (
      moduleIds &&
      selectedCourse.current &&
      selectedIntake.current &&
      selectedProgram.current &&
      selectedClient.current
    ) {
      // console.log('copy modules')
      await copyModules()
    } else if (
      quizIds &&
      selectedCourse.current &&
      selectedIntake.current &&
      selectedProgram.current &&
      selectedClient.current
    ) {
      // console.log('copy quizzes')
      await copyQuizzes()
    } else if (
      courseIds &&
      selectedIntake.current &&
      selectedProgram.current &&
      selectedClient.current
    ) {
      // console.log('copy courses')
      await copyCourses()
      emitReloadClient(selectedClient.current.id, true)
    } else if (intakeId && selectedProgram.current && selectedClient.current) {
      // console.log('copy intake')
      await copyIntake()
      emitReloadClient(selectedClient.current.id)
    } else if (programId && selectedClient.current) {
      // console.log('copy program')
      await copyProgram()
      emitReloadClient(selectedClient.current.id)
    }
  }

  const onShowCreateNew = (type: CreateType) => {
    // check if client is selected
    if (!selectedClient.current) {
      ToastUtil.warning('Please select a client first')
      return
    }

    switch (type) {
      case CreateType.Intake:
        // check if selected program is available
        if (!selectedProgram.current) {
          ToastUtil.warning('Please select a program first')
          return
        }
        break
      case CreateType.Course:
        // check if selected intake is available
        if (!selectedIntake.current) {
          ToastUtil.warning('Please select an intake first')
          return
        }
        break
      case CreateType.Module:
        // check if selected course is available
        if (!selectedCourse.current) {
          ToastUtil.warning('Please select a course first')
          return
        }
        break
    }

    // reset input
    if (refCreateInput.current) {
      refCreateInput.current.value = ''
    }
    setShowCreate(true)
    setCreateType(type)
  }

  const onCreateNew = async () => {
    try {
      const newValue = refCreateInput.current?.value
      if (!newValue || newValue.trim() === '') {
        ToastUtil.warning('Name cannot be empty')
        return
      }
      if (!selectedClient.current) {
        return
      }
      setIsCreating(true)
      switch (createType) {
        case CreateType.Program:
          const newProgram = await createArchProgram(
            selectedClient.current.id,
            newValue,
          )
          newProgram.intakes = []
          // add new program to selectedClient
          selectedClient.current.programs.push(newProgram)
          const programs = selectedClient.current.programs.map((program) => ({
            id: program.id,
            name: program.name,
            value: program.id,
          }))
          setProgramOptions([ProgramOptionLabel, ...programs])

          // select new program
          selectedProgram.current = newProgram
          // render by next tick
          setTimeout(() => {
            refDropDownMenuPrograms.current?.setSelectedItemId(newProgram.id)
            triggerCopy()
          }, 100)

          ToastUtil.success('Program created successfully')
          emitReloadClient(selectedClient.current.id)
          break
        case CreateType.Intake:
          if (!selectedProgram.current) {
            return
          }
          const newIntake = await createArchIntake(
            selectedProgram.current.id,
            newValue,
          )
          newIntake.courses = []
          // add new intake to selectedProgram
          selectedProgram.current.intakes.push(newIntake)
          const intakes = selectedProgram.current.intakes.map((intake) => ({
            id: intake.id,
            name: intake.name,
            value: intake.id,
          }))
          setIntakeOptions([IntakeOptionLabel, ...intakes])

          // select new intake
          selectedIntake.current = newIntake
          // render by next tick
          setTimeout(() => {
            refDropDownMenuIntakes.current?.setSelectedItemId(newIntake.id)
            triggerCopy()
          }, 100)

          ToastUtil.success('Intake created successfully')
          emitReloadClient(selectedClient.current.id)
          break
        case CreateType.Course:
          if (!selectedIntake.current) {
            return
          }
          const newCourse = await createArchCourse(
            selectedIntake.current.id,
            newValue,
          )
          newCourse.modules = []
          // add new course to selectedIntake
          selectedIntake.current.courses.push(newCourse)
          const courses = selectedIntake.current.courses.map((course) => ({
            id: course.id,
            name: course.name,
            value: course.id,
          }))
          setCourseOptions([CourseOptionLabel, ...courses])

          // select new course
          selectedCourse.current = newCourse
          // render by next tick
          setTimeout(() => {
            refDropDownMenuCourses.current?.setSelectedItemId(newCourse.id)
            triggerCopy()
          }, 100)

          ToastUtil.success('Course created successfully')
          emitReloadClient(selectedClient.current.id, true)
          break
        case CreateType.Module:
          if (!selectedCourse.current) {
            return
          }
          const newModule = await createArchModule(
            selectedCourse.current.id,
            newValue,
          )
          newModule.topics = []
          // add new module to selectedCourse
          selectedCourse.current.modules.push(newModule)
          const modules = selectedCourse.current.modules.map((module) => ({
            id: module.id,
            name: module.name,
            value: module.id,
          }))
          setModuleOptions([ModuleOptionLabel, ...modules])

          // select new module
          selectedModule.current = newModule
          // render by next tick
          setTimeout(() => {
            refDropDownMenuModules.current?.setSelectedItemId(newModule.id)
            triggerCopy()
          }, 100)

          ToastUtil.success('Module created successfully')
          break
        default:
          break
      }
      setShowCreate(false)
      setCreateType(null)
    } catch (error) {
      console.error(error)
      ToastUtil.error('Failed to create new')
    } finally {
      setIsCreating(false)
    }
  }

  useEffect(() => {
    fetchClients()
  }, [fetchClients])

  return (
    <>
      <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center">
        <div className="h-fit w-[50%] flex flex-col bg-white shadow-xl rounded-[12px] p-[12px] gap-[8px]">
          <div className="self-end cursor-pointer" onClick={onClose}>
            <Svg icon={Icon.Close} className={IconClass.FillBlack2} />
          </div>
          {/* <div className="flex flex-col">
          <p>{`programId: ${programId}`}</p>
          <p>{`intakeId: ${intakeId}`}</p>
          <p>{`courseIds: ${courseIds?.join(',')}`}</p>
          <p>{`moduleIds: ${moduleIds?.join(',')}`}</p>
          <p>{`topicIds: ${topicIds?.join(',')}`}</p>
          <p>{`documentIds: ${documentIds?.join(',')}`}</p>
        </div> */}
          <div className="flex flex-col items-center p-[12px] pb-[36px]">
            {isFetchingClients && <Spinner />}
            {!isFetchingClients && clients.length > 0 && (
              <div className="flex flex-col items-center gap-[24px] w-full">
                <div className="flex flex-row w-full items-center justify-center gap-[12px]">
                  <div className="w-3/4">
                    <DropDownMenu
                      items={clientOptions}
                      onSelected={onSelectClient}
                      border={true}
                    />
                  </div>
                  <button className={`${StyleUtil.buttonPrimary} opacity-0`}>
                    Create new
                  </button>
                </div>
                {(intakeId ||
                  courseIds ||
                  moduleIds ||
                  topicIds ||
                  documentIds ||
                  quizIds) && (
                  <div className="flex flex-row w-full items-center justify-center gap-[12px]">
                    <div className="w-3/4">
                      <DropDownMenu
                        items={programOptions}
                        onSelected={onSelectProgram}
                        border={true}
                        ref={refDropDownMenuPrograms}
                      />
                    </div>
                    <button
                      className={`${StyleUtil.buttonPrimary}`}
                      onClick={() => onShowCreateNew(CreateType.Program)}
                    >
                      Create new
                    </button>
                  </div>
                )}
                {(courseIds ||
                  moduleIds ||
                  topicIds ||
                  documentIds ||
                  quizIds) && (
                  <div className="flex flex-row w-full items-center justify-center gap-[12px]">
                    <div className="w-3/4">
                      <DropDownMenu
                        items={intakeOptions}
                        onSelected={onSelectIntake}
                        border={true}
                        ref={refDropDownMenuIntakes}
                      />
                    </div>
                    <button
                      className={`${StyleUtil.buttonPrimary}`}
                      onClick={() => onShowCreateNew(CreateType.Intake)}
                    >
                      Create new
                    </button>
                  </div>
                )}
                {(moduleIds || topicIds || documentIds || quizIds) && (
                  <div className="flex flex-row w-full items-center justify-center gap-[12px]">
                    <div className="w-3/4">
                      <DropDownMenu
                        items={courseOptions}
                        onSelected={onSelectCourse}
                        border={true}
                        ref={refDropDownMenuCourses}
                      />
                    </div>
                    <button
                      className={`${StyleUtil.buttonPrimary}`}
                      onClick={() => onShowCreateNew(CreateType.Course)}
                    >
                      Create new
                    </button>
                  </div>
                )}
                {(topicIds || documentIds) && (
                  <div className="flex flex-row w-full items-center justify-center gap-[12px]">
                    <div className="w-3/4">
                      <DropDownMenu
                        items={moduleOptions}
                        onSelected={onSelectModule}
                        border={true}
                        ref={refDropDownMenuModules}
                      />
                    </div>
                    <button
                      className={`${StyleUtil.buttonPrimary}`}
                      onClick={() => onShowCreateNew(CreateType.Module)}
                    >
                      Create new
                    </button>
                  </div>
                )}
              </div>
            )}
            {isCopying && <Spinner />}
            {isFetchingClient && <Spinner />}
          </div>
        </div>
      </div>
      {showCreate && (
        <div className="w-full h-full absolute bg-[#D5D5DBAA] z-[999] flex flex-col items-center justify-center">
          <div className="min-w-auto h-auto flex flex-col bg-white shadow-xl rounded-[12px] p-[12px] gap-[8px]">
            <div
              className="self-end cursor-pointer"
              onClick={() => {
                setShowCreate(false)
                setCreateType(null)
              }}
            >
              <Svg icon={Icon.Close} className={IconClass.FillBlack2} />
            </div>
            <div className="flex flex-col gap-[16px] items-center p-[12px]">
              <p className="arch-subheading self-start">
                Create new {createType}
              </p>
              <input
                ref={refCreateInput}
                type="text"
                placeholder={`Type ${createType} name here`}
                className="arch-add-input w-full border-[1px] border-[#6B69C1] p-[8px]"
                autoFocus={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onCreateNew()
                  }
                }}
              />
              <button className={StyleUtil.buttonPrimary} onClick={onCreateNew}>
                {isCreating ? <ButtonSpinner /> : 'Create'}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
