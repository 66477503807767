import { Category } from 'apis/entities/category.entity'
import {
  DocumentUser,
  Document,
  DocumentCheckerResponse,
} from 'apis/entities/document.entity'
import { Image } from 'apis/entities/image.entity'
import { Tag } from 'apis/entities/tag.entity'
import { UploadImage } from 'apis/entities/upload-image.entity'
import { User } from 'apis/entities/user.entity'
import { useContext, ReactNode, createContext, useRef } from 'react'
import axios from 'axios'
import { UploadFile } from 'apis/entities/upload-file.entity'
import { GeneratedQuestion } from 'apis/entities/quiz.entity'
import { BaseResponse } from 'apis/entities/base-response.entity'

type Props = {
  children: ReactNode
}

interface ApiValue {
  pingApiDocument: () => Promise<void>
  pingApiUtility: () => Promise<void>
  setToken: (token: string | null) => void
  getUser(auth0Id: string): Promise<User | null>
  publish(documentId: string): Promise<any>
  upload(body: FormData): Promise<any>
  uploadGrades(body: any): Promise<any>
  createTag(name: string): Promise<Tag>
  getTags(): Promise<Tag[]>
  getTagsByQuery(query: string): Promise<Tag[]>
  updateTag(id: string, name: string): Promise<boolean>
  deleteTag(id: string): Promise<boolean>
  createImage(
    name: string,
    url: string,
    size: number,
    tagNames?: string,
    tagIds?: string[],
    description?: string,
    categoryId?: string,
    subcategoryIds?: string[],
  ): Promise<Image>
  updateImage(
    id: string,
    tagIds?: string[],
    name?: string,
    description?: string,
  ): Promise<Image>
  bulkUpdateImages(
    imageIds: string[],
    tagIds?: string[],
    description?: string | undefined | null,
  ): Promise<boolean>
  deleteImage(id: string): Promise<boolean>
  getImagesByTagIds(tagIds: string): Promise<Image[]>
  getImagesByNoTag(): Promise<Image[]>
  getImagesBySearch(
    page: number,
    size: number,
    tagNames?: string,
    tagIds?: string[],
    categoryIds?: string[],
    subcategoryIds?: string[],
    description?: string,
  ): Promise<[Image[], number]>
  deleteImagesByTagIds(tagId: string, imageIds: string[]): Promise<boolean>
  deleteImages(imageIds: string[]): Promise<boolean>
  reassignTags(imageIds: string[], tagIds: string[]): Promise<boolean>
  reassignTagsAndSubcategories(
    imageIds: string[],
    tagIds: string[],
    subcategoryIds: string[],
  ): Promise<boolean>
  reassignTagsAndUpdateDescription(
    imageIds: string[],
    tagIds: string[],
    description?: string,
  ): Promise<boolean>
  reassignSubcategoriesAndDescription(
    imageIds: string[],
    subcategoryIds: string[],
    description?: string | undefined | null,
  ): Promise<boolean>
  reassign(
    imageIds: string[],
    props: {
      tagIds: string[]
      subcategoryIds: string[]
      description?: string
    },
  ): Promise<boolean>
  uploadImage(
    file: File,
    tagNames?: string,
    tagIds?: string[],
    description?: string,
    categoryId?: string,
    subcategoryIds?: string[],
  ): Promise<string | null>
  uploadFile(file: File): Promise<string | null>
  getCanvasAssignments(clientConnId: string, courseId: string): Promise<any>
  getCanvasClients(): Promise<any>
  getCanvasCourses(clientConnId: string): Promise<any>
  getCanvasMarkingSheet(
    courseId: string,
    assignmentId: string,
    clientId: string,
  ): Promise<any>
  getDocuments(
    filter?: string,
    search?: string,
    dateStart?: Date,
    dateEnd?: Date,
  ): Promise<any>
  getDocumentsV2(
    filter?: string,
    search?: string,
    dateStart?: Date,
    dateEnd?: Date,
    pageSize?: number,
    pageNumber?: number,
  ): Promise<any>
  getDocument(documentId: string): Promise<Document>
  getDocumentPublishedBy(): Promise<DocumentUser[]>
  deleteDocument(documentId: string): Promise<any>
  downloadDocument(documentId: string): Promise<any>
  updateDocument(documentId: string, modifications: object): Promise<any>
  updateDocumentByGeneratedQuestions(
    documentId: string,
    generatedQuestions: GeneratedQuestion[],
  ): Promise<Document>
  resetDocumentQuestions(documentId: string): Promise<Document>
  getLxFiles(
    filter?: string,
    search?: string,
    dateStart?: Date,
    dateEnd?: Date,
  ): Promise<any>
  getLxFilePublishedBy(): Promise<DocumentUser[]>
  deleteLxFile(fileId: string): Promise<any>
  updateLxFile(fileId: string, modifications: object): Promise<any>
  uploadLxFile(body: any, onProgress: any): Promise<any>
  getPrograms(): Promise<any>
  getProgram(assId: string): Promise<any>
  getImageCategories(): Promise<[Category]>
  documentChecker(docId: string): Promise<DocumentCheckerResponse>
  setImageCategory(
    categoryId: string,
    imageIds: string[],
  ): Promise<BaseResponse>
}

export const ApiContext = createContext<ApiValue | null>(null)

export function useApi(): ApiValue {
  const state = useContext(ApiContext)
  if (!state) {
    throw new Error('useApi must be used within ApiProvider')
  }
  return state
}

export function ApiProvider({ children }: Props) {
  const headers = { 'Content-Type': 'application/json' }
  const refToken = useRef<string | null>(null)
  const refHeaders = useRef<any>(headers)
  const documentHost = process.env.REACT_APP_DOCUMENT_API_URL_2 // switch all to AppRunner
  const utilityHost = process.env.REACT_APP_UTILITY_API_URL
  const hlpHost = process.env.REACT_APP_HLP_API_URL

  const setToken = (token: string | null) => {
    refToken.current = token
    refHeaders.current = {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  }

  const getUser = async (auth0Id: string): Promise<User | null> => {
    try {
      const api = `${hlpHost}/users/auth0Id/${auth0Id}`
      const res = await fetch(api, {
        method: 'GET',
        headers: refHeaders.current,
      })
      return res.json()
    } catch (err) {
      console.error(err)
      return null
    }
  }

  const pingApiDocument = async () => {
    const api = `${documentHost}`
    await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
  }

  const pingApiUtility = async () => {
    const api = `${utilityHost}`
    await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
  }

  const publish = async (documentId: string): Promise<any> => {
    const api = `${documentHost}/documents/${documentId}/publish`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const upload = async (body: any): Promise<any> => {
    const api = `${documentHost}/documents/upload`
    const res = await fetch(api, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${refToken.current}`,
      },
      body: body,
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const uploadGrades = async (body: any): Promise<any> => {
    const api = `${documentHost}/canvas-lms/upload-grades`
    const res = await fetch(api, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${refToken.current}`,
      },
      body: body,
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const getCanvasAssignments = async (
    clientConnId: string,
    courseId: string,
  ): Promise<any> => {
    const api = `${documentHost}/canvas-lms/assignments/${clientConnId}/${courseId}`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    const data = await res.json()
    if (data.assignments) {
      return data.assignments
    } else {
      throw res
    }
  }
  const getCanvasClients = async (): Promise<any> => {
    const res = await fetch(`${documentHost}/canvas-lms/clients`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    const data = await res.json()
    if (data.clients) {
      return data.clients
    } else {
      throw res
    }
  }
  const getCanvasCourses = async (clientConnId: string): Promise<any> => {
    const res = await fetch(
      `${documentHost}/canvas-lms/courses/${clientConnId}`,
      {
        method: 'GET',
        headers: refHeaders.current,
      },
    )
    const data = await res.json()
    if (data.courses) {
      return data.courses
    } else {
      throw res
    }
  }
  const getCanvasMarkingSheet = async (
    courseId: string,
    assignmentId: string,
    clientId: string,
  ): Promise<any> => {
    const res = await fetch(`${documentHost}/canvas-lms/export`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        courseId,
        assignmentId,
        clientId,
      }),
    })
    return res.blob()
  }
  const getDocumentPublishedBy = async (): Promise<DocumentUser[]> => {
    try {
      const api = `${documentHost}/documents/find/published-by`
      const res = await fetch(api, {
        method: 'GET',
        headers: refHeaders.current,
      })
      const result = await res.json()
      if (res.status >= 400) {
        throw result
      }
      const users = result as DocumentUser[]
      return users
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const getDocuments = async (
    filter?: string,
    search?: string,
    dateStart?: Date,
    dateEnd?: Date,
  ): Promise<any> => {
    let api = `${documentHost}/documents/find?${new URLSearchParams({
      ...(filter && { filter }),
      ...(search && { search }),
      ...(dateStart && { dateStart: dateStart.toISOString() }),
      ...(dateEnd && { dateEnd: dateEnd.toISOString() }),
    })}`
    try {
      const res = await fetch(api, {
        method: 'GET',
        headers: refHeaders.current,
      })
      return res.json()
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const getDocumentsV2 = async (
    filter?: string,
    search?: string,
    dateStart?: Date,
    dateEnd?: Date,
    pageSize?: number,
    pageNumber?: number,
  ): Promise<any> => {
    let api = `${documentHost}/documents/find-v2?${new URLSearchParams({
      ...(filter && { filter }),
      ...(search && { search }),
      ...(dateStart && { dateStart: dateStart.toISOString() }),
      ...(dateEnd && { dateEnd: dateEnd.toISOString() }),
      ...(pageSize && { pageSize: pageSize.toString() }),
      ...(pageNumber && { pageNumber: pageNumber.toString() }),
    })}`
    try {
      const res = await fetch(api, {
        method: 'GET',
        headers: refHeaders.current,
      })
      return res.json()
    } catch (err) {
      console.error(err)
      return {
        page: 1,
        pageSize: 10,
        items: [],
        totalItems: 0,
      }
    }
  }

  const getDocument = async (documentId: string): Promise<Document> => {
    // minimal data
    const api = `${documentHost}/documents/${documentId}/topics`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const deleteDocument = async (documentId: string): Promise<any> => {
    const api = `${documentHost}/documents/${documentId}`
    const res = await fetch(api, {
      method: 'DELETE',
      headers: refHeaders.current,
    })
    return res.json()
  }
  const downloadDocument = async (documentId: string): Promise<any> => {
    const api = `${documentHost}/documents/${documentId}/download`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.blob()
  }
  const updateDocument = async (
    documentId: string,
    modifications: object,
  ): Promise<any> => {
    const api = `${documentHost}/documents/${documentId}`
    const res = await fetch(api, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify(modifications),
    })
    if (res.status >= 400) {
      throw await res.json()
    }
    return res.json()
  }

  const uploadFile = async (file: File): Promise<string | null> => {
    try {
      const filename = file.name
      const data = await getPresignedUrl(filename)
      if (data.presigned_url) {
        const result = await putPresignedUrl(data.presigned_url, file)
        if (result) {
          return data.url
        }
      }
    } catch (err) {
      console.error(err)
    }
    return null
  }

  const uploadImage = async (
    file: File,
    tagNames?: string,
    tagIds?: string[],
    description?: string,
    categoryId?: string,
    subcategoryIds?: string[],
  ): Promise<string | null> => {
    try {
      const filename = file.name
      const data = await getPresignedUrlForImages(filename)
      const result = await putPresignedUrl(data.presigned_url, file)
      if (result) {
        const image = await createImage(
          filename,
          data.url,
          file.size,
          tagNames,
          tagIds,
          description,
          categoryId,
          subcategoryIds,
        )
        console.log(image)

        return data.url // same as image.url
      }
    } catch (err) {
      console.error(err)
    }
    return null
  }

  const getPresignedUrlForImages = async (
    filename: string,
  ): Promise<UploadImage> => {
    const body = { filename: filename }
    const api = `${utilityHost}/upload/images`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify(body),
    })
    return await res.json()
  }

  const getPresignedUrl = async (filename: string): Promise<UploadFile> => {
    const body = { filename: filename }
    const api = `${utilityHost}/upload`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify(body),
    })
    return await res.json()
  }

  const putPresignedUrl = async (
    presignedUrl: string,
    file: File,
  ): Promise<boolean> => {
    // if add header, it becomes downloadable file instead of viewing on browser
    // const headers = { 'Content-Type': 'application/octet-stream' }
    const res = await fetch(presignedUrl, {
      method: 'PUT',
      // headers: refHeaders.current,
      body: file,
    })
    return res.status === 200
  }

  const createTag = async (name: string): Promise<Tag> => {
    const body = { name: name }
    const api = `${utilityHost}/tags`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify(body),
    })
    return await res.json()
  }

  const getTags = async (): Promise<Tag[]> => {
    const api = `${utilityHost}/tags`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    const data = await res.json()
    return data['tags']
  }

  const getTagsByQuery = async (query: string): Promise<Tag[]> => {
    const api = `${utilityHost}/tags/search?q=${query}`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    const data = await res.json()
    return data['tags']
  }

  const updateTag = async (id: string, name: string): Promise<boolean> => {
    const api = `${utilityHost}/tags/${id}`
    const res = await fetch(api, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify({ name: name }),
    })
    const data = await res.json()
    return data.affected > 0
  }

  const deleteTag = async (id: string): Promise<boolean> => {
    const api = `${utilityHost}/tags/${id}`
    const res = await fetch(api, {
      method: 'DELETE',
      headers: refHeaders.current,
    })
    const data = await res.json()
    return data['success']
  }

  const createImage = async (
    name: string,
    url: string,
    size: number,
    tagNames?: string,
    tagIds?: string[],
    description?: string,
    categoryId?: string,
    subcategoryIds?: string[],
  ): Promise<Image> => {
    const body = {
      name: name,
      url: url,
      size: size,
      tagNames: tagNames,
      tagIds: tagIds,
      description: description,
      categoryId: categoryId,
      subcategoryIds: subcategoryIds,
    }
    const api = `${utilityHost}/images`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify(body),
    })
    return await res.json()
  }

  const updateImage = async (
    id: string,
    tagIds?: string[],
    name?: string,
    description?: string,
  ) => {
    let body: any = {}
    if (tagIds) body['tagIds'] = tagIds
    if (name) body['name'] = name
    if (description) body['description'] = description

    const api = `${utilityHost}/images/${id}`
    const res = await fetch(api, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify(body),
    })
    return await res.json()
  }

  const bulkUpdateImages = async (
    imageIds: string[],
    tagIds?: string[],
    description?: string | undefined | null,
  ): Promise<boolean> => {
    let body: any = {}
    if (imageIds) body['imageIds'] = imageIds
    if (tagIds) body['tagIds'] = tagIds
    if (description !== undefined) body['description'] = description

    try {
      const api = `${utilityHost}/images/bulk-update`
      const res = await fetch(api, {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      })
      const result = await res.json()
      return result['success']
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const deleteImage = async (id: string): Promise<boolean> => {
    const api = `${utilityHost}/images/${id}`
    try {
      const res = await fetch(api, {
        method: 'DELETE',
        headers: refHeaders.current,
      })
      const data = await res.json()
      return data['success']
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const getImagesByTagIds = async (tagIds: string): Promise<Image[]> => {
    const api = `${utilityHost}/images/tags/${tagIds}`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    const data = await res.json()
    return data['images']
  }

  const getImagesByNoTag = async (): Promise<Image[]> => {
    const api = `${utilityHost}/images/tags/no-tag`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    const data = await res.json()
    return data['images']
  }

  const getImagesBySearch = async (
    page: number,
    size: number,
    tagNames?: string,
    tagIds?: string[],
    categoryIds?: string[],
    subcategoryIds?: string[],
    description?: string,
  ): Promise<[Image[], number]> => {
    const body = {
      page: page,
      size: size,
      tagNames: tagNames,
      tagIds: tagIds,
      categoryIds: categoryIds,
      subcategoryIds: subcategoryIds,
      description: description,
    }
    const api = `${utilityHost}/images/search`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify(body),
    })
    const data = await res.json()
    return [data['images'], data['nextPage']]
  }

  const deleteImagesByTagIds = async (
    tagId: string,
    imageIds: string[],
  ): Promise<boolean> => {
    try {
      const api = `${utilityHost}/tags/${tagId}/delete-images`
      const body = { imageIds: imageIds }
      const res = await fetch(api, {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      })
      const data = await res.json()
      return data['success']
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const deleteImages = async (imageIds: string[]): Promise<boolean> => {
    try {
      const api = `${utilityHost}/images/bulk-delete`
      const body = { imageIds: imageIds }
      const res = await fetch(api, {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      })
      const data = await res.json()
      return data['success']
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const reassignTags = async (
    imageIds: string[],
    tagIds: string[],
  ): Promise<boolean> => {
    try {
      const api = `${utilityHost}/images/reassign-tags`
      const body = { imageIds: imageIds, tagIds: tagIds }
      const res = await fetch(api, {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      })
      const data = await res.json()
      return data['success']
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const reassignTagsAndSubcategories = async (
    imageIds: string[],
    tagIds: string[],
    subcategoryIds: string[],
  ): Promise<boolean> => {
    try {
      const api = `${utilityHost}/images/reassign-tags-and-subcategories`
      const body = {
        imageIds: imageIds,
        tagIds: tagIds,
        subcategoryIds: subcategoryIds,
      }
      const res = await fetch(api, {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      })
      const data = await res.json()
      return data['success']
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const reassignTagsAndUpdateDescription = async (
    imageIds: string[],
    tagIds: string[],
    description?: string,
  ): Promise<boolean> => {
    try {
      if (imageIds.length > 0 && tagIds.length > 0) {
        await reassignTags(imageIds, tagIds)
      }
      if (description) {
        for (const imageId of imageIds) {
          await updateImage(imageId, undefined, undefined, description)
        }
      }
      return true
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const reassignSubcategoriesAndDescription = async (
    imageIds: string[],
    subcategoryIds: string[],
    description?: string | undefined | null,
  ): Promise<boolean> => {
    try {
      const api = `${utilityHost}/images/reassign-subcategories-and-description`
      let body: any = {}
      if (imageIds) body['imageIds'] = imageIds
      if (subcategoryIds) body['subcategoryIds'] = subcategoryIds
      if (description !== undefined) body['description'] = description
      const res = await fetch(api, {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify(body),
      })
      const data = await res.json()
      return data['success']
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const reassign = async (
    imageIds: string[],
    props: {
      tagIds: string[]
      subcategoryIds: string[]
      description?: string
    },
  ): Promise<boolean> => {
    try {
      if (imageIds.length > 0 && props.tagIds.length > 0) {
        await reassignTags(imageIds, props.tagIds)
      }

      const resp1 = await fetch(
        `${utilityHost}/images/reassign-subcategories-and-description`,
        {
          method: 'POST',
          headers: refHeaders.current,
          body: JSON.stringify({
            imageIds: imageIds,
            ...(props.subcategoryIds && {
              subcategoryIds: props.subcategoryIds,
            }),
            ...(props.description !== undefined && {
              description: props.description,
            }),
          }),
        },
      )

      const data1 = await resp1.json()

      return data1['success']
    } catch (err) {
      console.error(err)
      return false
    }
  }

  const getPrograms = async (): Promise<any> => {
    const res = await fetch(`${documentHost}/documents/assessment/programs`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const getProgram = async (assId: string): Promise<any> => {
    const res = await fetch(
      `${documentHost}/documents/assessment/program/${assId}`,
      {
        method: 'GET',
        headers: refHeaders.current,
      },
    )
    return res.json()
  }

  const getImageCategories = async (): Promise<[Category]> => {
    const api = `${utilityHost}/categories`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    const data = await res.json()
    return data['categories']
  }

  const getLxFiles = async (
    filter?: string,
    search?: string,
    dateStart?: Date,
    dateEnd?: Date,
  ): Promise<any> => {
    let api = `${documentHost}/lx-files/find?${new URLSearchParams({
      ...(filter && { filter }),
      ...(search && { search }),
      ...(dateStart && { dateStart: dateStart.toISOString() }),
      ...(dateEnd && { dateEnd: dateEnd.toISOString() }),
    })}`
    try {
      const res = await fetch(api, {
        method: 'GET',
        headers: refHeaders.current,
      })
      return res.json()
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const getLxFilePublishedBy = async (): Promise<DocumentUser[]> => {
    try {
      const api = `${documentHost}/lx-files/find/published-by`
      const res = await fetch(api, {
        method: 'GET',
        headers: refHeaders.current,
      })
      return await res.json()
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const updateLxFile = async (
    documentId: string,
    modifications: object,
  ): Promise<any> => {
    const api = `${documentHost}/lx-files/${documentId}`
    const res = await fetch(api, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify(modifications),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const deleteLxFile = async (documentId: string): Promise<any> => {
    const api = `${documentHost}/lx-files/${documentId}`
    const res = await fetch(api, {
      method: 'DELETE',
      headers: refHeaders.current,
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const uploadLxFile = async (formData: any, onProgress: any): Promise<any> => {
    const formObject = Object.fromEntries(formData.entries())
    const filename = formObject.filenameOverride

    const preResp: any = await axios.post(
      `${documentHost}/lx-files/upload/presigned-url`,
      {
        filename: filename,
        filesize: formObject.file.size,
      },
      { headers: refHeaders.current },
    )
    const presignedUrls = preResp.data.presignedUrls
    if (presignedUrls.length > 1) {
      const chunkSize = Math.ceil(formObject.file.size / presignedUrls.length)
      const etags = []
      for (let i = 0; i < presignedUrls.length; i++) {
        const start = i * chunkSize
        const end = (i + 1) * chunkSize
        const chunk = formObject.file.slice(start, end)

        const resp = await axios.put(
          presignedUrls[i],

          // `https://${preResp.data.s3bucket}.s3.amazonaws.com/${
          //   preResp.data.s3key
          // }?partNumber=${i + 1}&uploadId=${preResp.data.uploadId}`,
          chunk,
          {
            onUploadProgress: (progressEvent) => {
              const base = (i / presignedUrls.length) * 100
              const percentCompleted = progressEvent.total
                ? (100 * progressEvent.loaded) /
                  progressEvent.total /
                  presignedUrls.length
                : 0
              onProgress && onProgress(Math.round(base + percentCompleted))
            },
            headers: {
              ...(preResp.data.contentType && {
                'Content-Type': preResp.data.contentType,
              }),
            },
          },
        )
        etags.push(resp.headers.etag)
      }

      await axios.post(
        `${documentHost}/lx-files/upload/multipart-complete`,
        {
          lxFileId: preResp.data.lxFileId,
          filename: filename,
          uploadId: preResp.data.uploadId,
          etags,
        },
        { headers: refHeaders.current },
      )
    } else {
      await axios.put(presignedUrls[0], formObject.file, {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = progressEvent.total
            ? Math.round((progressEvent.loaded * 100) / progressEvent.total)
            : 0
          onProgress && onProgress(percentCompleted)
        },
        headers: {
          ...(preResp.data.contentType && {
            'Content-Type': preResp.data.contentType,
          }),
        },
      })
    }
    // console.log(preResp.data)
    const res = await axios.post(
      `${documentHost}/lx-files/upload`,
      {
        lxFileId: preResp.data.lxFileId,
        auth0Id: formObject.auth0Id,
        filenameOverride: formObject.filenameOverride,
        username: formObject.username,
        description: formObject.description,
        url: preResp.data.url,
        fileSize: formObject.file.size,
      },
      {
        headers: {
          Authorization: `Bearer ${refToken.current}`,
        },
      },
    )
    return res.data
  }

  const updateDocumentByGeneratedQuestions = async (
    documentId: string,
    generatedQuestions: GeneratedQuestion[],
  ): Promise<Document> => {
    const api = `${documentHost}/documents/${documentId}/regenerate-questions`
    const res = await fetch(api, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify({ generatedQuestions: generatedQuestions }),
    })
    return res.json()
  }

  const resetDocumentQuestions = async (
    documentId: string,
  ): Promise<Document> => {
    const api = `${documentHost}/documents/${documentId}/reset-questions`
    const res = await fetch(api, {
      method: 'PATCH',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const documentChecker = async (
    documentId: string,
  ): Promise<DocumentCheckerResponse> => {
    const res = await fetch(`${documentHost}/documents/${documentId}/checker`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }
  const setImageCategory = async (
    categoryId: string,
    imageIds: string[],
  ): Promise<BaseResponse> => {
    try {
      const res = await fetch(`${utilityHost}/images/change-category`, {
        method: 'POST',
        headers: refHeaders.current,
        body: JSON.stringify({ categoryId: categoryId, imageIds: imageIds }),
      })
      const data = await res.json()
      return data
    } catch (err) {
      console.error(err)
      return {
        success: false,
      }
    }
  }
  const providerValue = {
    pingApiDocument,
    pingApiUtility,
    setToken,
    getUser,
    publish,
    upload,
    uploadGrades,
    uploadImage,
    updateDocument,
    updateDocumentByGeneratedQuestions,
    resetDocumentQuestions,
    getCanvasAssignments,
    getCanvasClients,
    getCanvasCourses,
    getCanvasMarkingSheet,
    getDocuments,
    getDocumentsV2,
    getDocument,
    getDocumentPublishedBy,
    deleteDocument,
    downloadDocument,
    createTag,
    getTags,
    getTagsByQuery,
    updateTag,
    deleteTag,
    createImage,
    updateImage,
    bulkUpdateImages,
    deleteImage,
    getImagesByTagIds,
    getImagesByNoTag,
    getImagesBySearch,
    deleteImagesByTagIds,
    deleteImages,
    reassignTags,
    reassignTagsAndSubcategories,
    reassignTagsAndUpdateDescription,
    reassignSubcategoriesAndDescription,
    reassign,
    getPrograms,
    getProgram,
    getImageCategories,
    getLxFiles,
    getLxFilePublishedBy,
    deleteLxFile,
    updateLxFile,
    uploadLxFile,
    uploadFile,
    documentChecker,
    setImageCategory,
  }

  return (
    <ApiContext.Provider value={providerValue}>{children}</ApiContext.Provider>
  )
}
