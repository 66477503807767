interface LargeSpinnerProps {
  text: string
}

export default function LargeSpinner(props: LargeSpinnerProps) {
  return (
    <div>
      <div className="flex flex-col items-center justify-center gap-[36px]">
        <div
          className="flex flex-row items-center justify-center"
          style={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontWeight: 600,
            lineHeight: '24px',
          }}
        >
          <p>{props.text}</p>
        </div>

        <div>
          <svg
            width="231"
            height="230"
            viewBox="0 0 231 230"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M115.25 28.75C162.885 28.75 201.5 67.3655 201.5 115C201.5 162.635 162.885 201.25 115.25 201.25C67.6155 201.25 29 162.635 29 115C29 67.3655 67.6155 28.75 115.25 28.75Z"
              stroke="#F3F3F5"
              strokeWidth="23"
              strokeLinecap="round"
            />
            <path
              d="M115.25 28.75C162.885 28.75 201.5 67.3655 201.5 115"
              stroke="#BD69FE"
              strokeWidth="23"
              strokeLinecap="round"
            />
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 0 0"
              to="360 0 0"
              dur="1s"
              repeatCount="indefinite"
            />
          </svg>
        </div>
      </div>
    </div>
  )
}
