import { Quiz, QuizStatus } from 'apis/entities/quiz.entity'
import Path from 'routes/Path'
import { getStatusStyle, getStatusText } from 'utils/QuizUtil'
import { getDateText } from 'utils/StringUtil'

interface Props {
  quiz: Quiz
}

export const QuizStatusText = ({ quiz }: Props) => {
  const onClickStatus = (quiz: Quiz) => {
    // new tab
    window.open(`${Path.quizzes.path}/${quiz.id}`, '_blank')
  }

  return (
    <div className="flex flex-row items-center gap-1 w-full justify-center text-[14px] font-roboto font-[600]">
      {quiz.status !== QuizStatus.Sent && (
        <div
          className={getStatusStyle(quiz)}
          onClick={() => onClickStatus(quiz)}
        >
          {getStatusText(quiz)}
        </div>
      )}
      {quiz.status === QuizStatus.Sent && quiz.deletedAt !== null && (
        <div
          className={getStatusStyle(quiz)}
          onClick={() => onClickStatus(quiz)}
        >
          {getStatusText(quiz)}
        </div>
      )}
      {quiz.status === QuizStatus.Sent && quiz.deletedAt === null && (
        <div className="flex flex-row items-center justify-center pl-[12px] pr-[18px]">
          <div className="flex flex-col flex-1">
            <div
              className={getStatusStyle(quiz)}
              onClick={() => onClickStatus(quiz)}
            >
              {getStatusText(quiz)}
            </div>
            <div
              className={getStatusStyle(quiz)}
              onClick={() => onClickStatus(quiz)}
            >
              {getDateText(quiz.sentAt)}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
