import {
  createContext,
  useContext,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { User as Auth0User, useAuth0 } from '@auth0/auth0-react'
import jwt_decode from 'jwt-decode'
import { useApi } from './ApiProvider'
import { User } from 'apis/entities/user.entity'
import { isPrd } from 'utils/EnvUtil'
import { useArchitectureApi } from './ArchitectureApiProvider'
import { useQuizApi } from './QuizApiProvider'

// Lx roles
export enum Role {
  None = '',
  Author = 'LxAuthor',
  Designer = 'LxDesigner',
  Admin = 'LxAdmin',
  SuperAdmin = 'LxSuperAdmin',
}

export enum Permission {
  CreateAuthoring = 'create:lx_authoring',
  PublishAuthoring = 'publish:lx_authoring',
  ReadSouring = 'read:lx_sourcing',
  ReadCatalog = 'read:lx_catalog',
  UpdateCatalog = 'update:lx_catalog',
  DeleteCatalog = 'delete:lx_catalog',
  CreateImages = 'create:lx_images',
  ReadImages = 'read:lx_images',
  UpdateImages = 'update:lx_images',
  DeleteImages = 'delete:lx_images',
  CreateQuizzes = 'create:lx_quizzes',
  UpdateQuizzes = 'update:lx_quizzes',
  ReadQuizzes = 'read:lx_quizzes',
  DeleteQuizzes = 'delete:lx_quizzes',
  CreateArchitectures = 'create:lx_architectures',
  UpdateArchitectures = 'update:lx_architectures',
  ReadArchitectures = 'read:lx_architectures',
  DeleteArchitectures = 'delete:lx_architectures',
}

export type Auth0UserDetails = {
  id: string
  email: string
  username: string
  firstName: string
}

type Props = {
  children: ReactNode
}

interface AuthValue {
  auth0User: Auth0User | undefined
  mentemUser: User | undefined
  permissions: string[]
  getRole: () => Role
  hasPermission: (permission: Permission) => boolean
  isLogged: boolean
  isLoadingMentemUser: boolean
  getAuth0UserDetails: () => Auth0UserDetails | undefined
  isSuperAdmin: boolean
}

export const AuthContext = createContext<AuthValue | null>(null)

export function useAuth(): AuthValue {
  const state = useContext(AuthContext)
  if (!state) {
    throw new Error('useAuth must be used within AuthProvider')
  }
  return state
}

const AuthProvider = ({ children }: Props) => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()
  const [auth0User, setAuth0User] = useState<Auth0User | undefined>(undefined)
  const [mentemUser, setMentemUser] = useState<User | undefined>(undefined)
  const [permissions, setPermissions] = useState<string[]>([])
  const [isLogged, setIsLogged] = useState<boolean>(false)
  const [isLoadingMentemUser, setIsLoadingMentemUser] = useState<boolean>(false)
  const { setToken, getUser } = useApi()
  const { setToken: setTokenForArchitectureApi } = useArchitectureApi()
  const { setToken: setTokenForQuizApi } = useQuizApi()

  const getRole = (): Role => {
    if (isAuthenticated && user) {
      const auth0roles: string[] =
        user[`${process.env.REACT_APP_AUTH0_CUSTOM_CLAIMS_NAMESPACE}/roles`]
      const mentemRoles: string[] = mentemUser?.roles.map((r) => r.name) || []
      const roles = auth0roles.concat(mentemRoles)
      if (roles.includes(Role.SuperAdmin)) {
        return Role.SuperAdmin
      } else if (roles.includes(Role.Admin)) {
        return Role.Admin
      } else if (roles.includes(Role.Author)) {
        return Role.Author
      } else if (roles.includes(Role.Designer)) {
        return Role.Designer
      }
    }
    return Role.None
  }

  const hasPermission = (permission: Permission): boolean => {
    return permissions.includes(permission)
  }

  const getUserPermissions = (u: User): string[] => {
    if (u && u.roles && u.roles.length > 0) {
      const permissions = u.roles
        .map((r) => r.permissions)
        .reduce((prev, curr) => {
          return [...prev, ...curr]
        }, [])
      return permissions
    }
    return []
  }

  const getAuth0UserDetails = (): Auth0UserDetails | undefined => {
    if (isAuthenticated && user) {
      const id = user?.sub || ''
      let username = ''
      if (user?.given_name && user?.family_name) {
        username = `${user?.given_name} ${user?.family_name}`
      } else {
        username = user?.nickname || user?.name || ''
      }
      const firstName = user?.given_name || ''
      const email = user?.email || ''
      return { id, email, username, firstName }
    }
    return undefined
  }

  useEffect(() => {
    if (isAuthenticated) {
      setAuth0User(user)
      if (!isPrd) {
        console.log('user', user)
      }
      const getPermissionsFromToken = async () => {
        const token = await getAccessTokenSilently()
        const decodedToken: any = jwt_decode(token)
        if (!isPrd) {
          console.log('decodedToken', decodedToken)
        }

        setToken(token)
        setTokenForArchitectureApi(token)
        setTokenForQuizApi(token)

        setIsLogged(true)
        setIsLoadingMentemUser(true)
        const user = await getUser(decodedToken.sub)
        setIsLoadingMentemUser(false)
        // console.log(user)
        let permissions: string[] = []
        if (user && user.roles && user.roles.length > 0) {
          setMentemUser(user)
          // mentem permissions
          permissions = getUserPermissions(user)
        }
        // plus auth0 permissions
        permissions = permissions.concat(decodedToken.permissions)
        // dedupe string array
        permissions = [...new Set(permissions)]
        setPermissions(permissions)
      }
      getPermissionsFromToken()
    }
  }, [
    setAuth0User,
    setToken,
    user,
    getUser,
    getAccessTokenSilently,
    isAuthenticated,
    setTokenForArchitectureApi,
    setTokenForQuizApi,
  ])

  const providerValue = {
    auth0User,
    mentemUser,
    permissions,
    getRole,
    hasPermission,
    isLogged,
    isLoadingMentemUser,
    getAuth0UserDetails,
    isSuperAdmin: getRole() === Role.SuperAdmin,
  }

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  )
}

export default AuthProvider
