import SidebarLayout from 'components/SidebarLayout'
import WidgetUploadQuiz from 'views/WidgetUploadQuiz'
import { useEffect, useState } from 'react'
import { StyleUtil } from 'utils/StyleUtil'
import { Role, useAuth } from 'providers/AuthProvider'
import { useNavigate } from 'react-router-dom'
import Path from 'routes/Path'
import ManageQuizzes from 'views/ManageQuizzes'
import WidgetQuestionGenerator from 'views/WidgetQuestionGenerator'

function QuizzeswPage() {
  const navigate = useNavigate()
  const { getRole } = useAuth()
  const [showUploadWidget, setShowUploadWidget] = useState<boolean>(false)
  const [showQuestionGeneratorWidget, setShowQuestionGeneratorWidget] =
    useState<boolean>(false)

  useEffect(() => {
    if (getRole() === Role.Designer) {
      // redirect to home
      navigate(Path.root.path)
      return
    }
  }, [getRole, navigate])

  return (
    <>
      <SidebarLayout>
        <div className={StyleUtil.container}>
          <p className={StyleUtil.headline}>Mentem quiz review tool</p>
          <div className="flex flex-col items-center justify-center mt-8">
            <ManageQuizzes
              onCreateNew={(
                clientId: string,
                programId: string,
                intakeId: string,
              ) => {
                navigate(
                  Path.quizzes.path +
                    `/create?clientId=${clientId}&programId=${programId}&intakeId=${intakeId}`,
                )
              }}
            />
          </div>
        </div>
      </SidebarLayout>
      {showUploadWidget && (
        <WidgetUploadQuiz
          placeholder="Drag spreadsheet here to upload"
          onClose={(_refresh) => {
            setShowUploadWidget(false)
          }}
          onRedirect={(path) => {
            navigate(path)
          }}
        />
      )}
      {showQuestionGeneratorWidget && (
        <WidgetQuestionGenerator
          multiple={true}
          onGenerated={() => {
            setShowQuestionGeneratorWidget(false)
          }}
          onClose={() => {
            setShowQuestionGeneratorWidget(false)
          }}
        />
      )}
    </>
  )
}

export default QuizzeswPage
