import { useContext, ReactNode, createContext, useRef } from 'react'
import {
  GeneratedQuestion,
  Quiz,
  QuizCreator,
  QuizEditor,
  QuizQuestion,
  QuizQuestionFile,
} from 'apis/entities/quiz.entity'
import { BaseResponse } from 'apis/entities/base-response.entity'

type Props = {
  children: ReactNode
}

interface ApiValue {
  setToken: (token: string | null) => void
  generateQuestions(documentId: string, nQuestions?: number): Promise<any>
  generateMultipleQuestionsStart(documentIds: string[], n: number): Promise<any>
  generateMultipleQuestionsV2(topicIds: string[], n: number): Promise<any>
  generateMultipleQuestionsStatus(jobId: string): EventSource
  getQuestionJob(documentId: string): Promise<any>
  getAllQuestionJobs(): Promise<any>
  saveQuestionJob(docId: string, questions: any): Promise<any>
  generateNewQuestions(
    quizId: string,
    documentId: string,
    nQuestions?: number,
  ): Promise<GeneratedQuestion[]>
  generateNewQuestionsV2(
    quizId: string,
    topicId: string,
    nQuestions?: number,
  ): Promise<GeneratedQuestion[]>
  regenerateQuestion(
    questionId: string,
    learningOutcome: string,
  ): Promise<QuizQuestion>
  getQuestions(
    assessmentId?: string,
    courseName?: string,
    courseLevel?: number,
    moduleName?: string,
    topicNames?: string[],
  ): Promise<any>
  updateQuestion(
    assId: string,
    questionId: string,
    modifications: object,
  ): Promise<any>
  uploadFileForQuiz(body: FormData): Promise<Quiz>
  addQuizReviewers(quizId: string, emails: string[]): Promise<any>
  getQuizzes(
    search?: string,
    updatedBy?: string,
    dateStart?: Date,
    dateEnd?: Date,
    withDeleted?: boolean,
  ): Promise<Quiz[]>
  getQuiz(quizId: string): Promise<Quiz>
  updateQuiz(
    quizId: string,
    name?: string,
    description?: string,
    positions?: string[],
    auth0Id?: string,
    username?: string,
    firstName?: string,
    email?: string,
  ): Promise<any>
  approveQuiz(quizId: string, approved: boolean): Promise<any>
  updateQuizQuestion(
    quizId: string,
    questionId: string,
    question: any,
  ): Promise<any>
  deleteQuiz(quizId: string): Promise<any>
  exportQuiz(quizId: string): Promise<any>
  addQuizQuestionFile(
    quizId: string,
    questionId: string,
    url: string,
    type: string,
    name: string,
    size: number,
    version: number,
  ): Promise<QuizQuestionFile>
  deleteQuizQuestionFile(
    quizId: string,
    questionId: string,
    fileId: string,
  ): Promise<BaseResponse>
  batchDeleteQuizzes(ids: string[]): Promise<BaseResponse>
  batchRestoreQuizzes(ids: string[]): Promise<BaseResponse>
  getQuizCreatedBy(): Promise<QuizCreator[]>
  getQuizUpdatedBy(): Promise<QuizEditor[]>
  createQuiz(
    name: string,
    description?: string,
    auth0Id?: string,
    username?: string,
    firstName?: string,
    email?: string,
    questions?: QuizQuestion[],
    documentIds?: string[],
    courseId?: string,
  ): Promise<Quiz>
  addQuizQuestion(quizId: string, question: QuizQuestion): Promise<QuizQuestion>
  deleteQuizQuestion(quizId: string, questionId: string): Promise<BaseResponse>
  resendEmailToReviewer(
    quizId: string,
    reviewerId: string,
  ): Promise<BaseResponse>
}

export const QuizApiContext = createContext<ApiValue | null>(null)

export function useQuizApi(): ApiValue {
  const state = useContext(QuizApiContext)
  if (!state) {
    throw new Error('useQuizApi must be used within QuizApiProvider')
  }
  return state
}

export function QuizApiProvider({ children }: Props) {
  const headers = { 'Content-Type': 'application/json' }
  const refToken = useRef<string | null>(null)
  const refHeaders = useRef<any>(headers)
  const documentHost = process.env.REACT_APP_DOCUMENT_API_URL_2 // switch all to AppRunner

  const setToken = (token: string | null) => {
    refToken.current = token
    refHeaders.current = {
      ...headers,
      Authorization: `Bearer ${token}`,
    }
  }

  const generateQuestions = async (
    documentId: string,
    nQuestions: number = 1,
  ): Promise<any> => {
    const api = `${documentHost}/gpt/generate-questions`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        // id: documentId, using by hlp-api
        docId: documentId,
        nQuestions: nQuestions,
      }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const generateMultipleQuestionsStart = async (
    documentIds: string[],
    nQuestions: number,
  ): Promise<any> => {
    const api = `${documentHost}/gpt/generate-questions-multiple-topics/start`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        documentIds: documentIds,
        nQuestions: nQuestions,
      }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const generateMultipleQuestionsV2 = async (
    topicIds: string[],
    nQuestions: number,
  ): Promise<any> => {
    const api = `${documentHost}/gpt/generate-questions-multiple-topics/v2`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        topicIds: topicIds,
        nQuestions: nQuestions,
      }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const generateMultipleQuestionsStatus = (jobId: string): EventSource => {
    const api = `${documentHost}/gpt/generate-questions-multiple-topics/status?jobId=${jobId}`
    // return new EventSource(api)
    const apiWithHeaders = `${api}&headers=${encodeURIComponent(
      JSON.stringify(refHeaders.current),
    )}`
    return new EventSource(apiWithHeaders)
  }

  const getQuestionJob = async (documentId: string): Promise<any> => {
    const api = `${documentHost}/gpt/get-job/${documentId}`
    const res = await fetch(api, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const getAllQuestionJobs = async (): Promise<any> => {
    const res = await fetch(`${documentHost}/gpt/get-all-jobs`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const saveQuestionJob = async (
    docId: string,
    questions: any,
  ): Promise<any> => {
    const api = `${documentHost}/gpt/save-question-job`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        // id: documentId, using by hlp-api
        docId: docId,
        questions: questions,
      }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const generateNewQuestions = async (
    quizId: string,
    documentId: string,
    nQuestions: number = 1,
  ): Promise<GeneratedQuestion[]> => {
    const api = `${documentHost}/gpt/generate-new-questions`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        quizId: quizId,
        documentId: documentId,
        nQuestions: nQuestions,
      }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const generateNewQuestionsV2 = async (
    quizId: string,
    topicId: string,
    nQuestions: number = 1,
  ): Promise<GeneratedQuestion[]> => {
    const api = `${documentHost}/gpt/generate-new-questions/v2`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        quizId: quizId,
        topicId: topicId,
        nQuestions: nQuestions,
      }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const regenerateQuestion = async (
    questionId: string,
    learningOutcome: string,
  ): Promise<QuizQuestion> => {
    const api = `${documentHost}/gpt/regenerate-question`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        questionId: questionId,
        learningOutcome: learningOutcome,
      }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const getQuestions = async (
    assessmentId?: string,
    courseName?: string,
    courseLevel?: number,
    moduleName?: string,
    topicNames?: string[],
  ): Promise<any> => {
    const res = await fetch(`${documentHost}/documents/question/search`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        assessmentId: assessmentId,
        courseName: courseName,
        courseLevel: courseLevel,
        moduleName: moduleName,
        topicNames: topicNames,
      }),
    })
    return res.json()
  }

  const updateQuestion = async (
    assId: string,
    questionId: string,
    modifications: object,
  ): Promise<any> => {
    const api = `${documentHost}/documents/question/${assId}/${questionId}`
    const res = await fetch(api, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify(modifications),
    })
    return res.json()
  }

  const uploadFileForQuiz = async (body: FormData): Promise<Quiz> => {
    const api = `${documentHost}/quizzes/upload`
    const res = await fetch(api, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${refToken.current}`,
      },
      body: body,
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const addQuizReviewers = async (
    quizId: string,
    emails: string[],
  ): Promise<any> => {
    const api = `${documentHost}/quizzes/${quizId}/reviewers`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ emails: emails }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const getQuizzes = async (
    search?: string,
    updatedBy?: string,
    dateStart?: Date,
    dateEnd?: Date,
    withDeleted?: boolean,
  ): Promise<Quiz[]> => {
    let query = new URLSearchParams()
    if (search) query.append('search', search)
    if (updatedBy) query.append('updatedBy', updatedBy)
    if (dateStart) query.append('dateStart', dateStart.toISOString())
    if (dateEnd) query.append('dateEnd', dateEnd.toISOString())
    if (withDeleted) query.append('withDeleted', String(withDeleted))
    const res = await fetch(`${documentHost}/quizzes?${query}`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const getQuiz = async (quizId: string): Promise<Quiz> => {
    const res = await fetch(`${documentHost}/quizzes/${quizId}`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const updateQuiz = async (
    quizId: string,
    name?: string,
    description?: string,
    positions?: string[],
    auth0Id?: string,
    username?: string,
    firstName?: string,
    email?: string,
  ): Promise<any> => {
    const res = await fetch(`${documentHost}/quizzes/${quizId}`, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify({
        name: name,
        description: description,
        positions: positions,
        updatedByAuth0Id: auth0Id,
        updatedByUserName: username,
        updatedByFirstName: firstName,
        updatedByEmail: email,
      }),
    })
    return res.json()
  }

  const approveQuiz = async (
    quizId: string,
    approved: boolean,
  ): Promise<any> => {
    const res = await fetch(`${documentHost}/quizzes/${quizId}`, {
      method: 'PATCH',
      headers: refHeaders.current,
      body: JSON.stringify({
        approved: approved,
      }),
    })
    return res.json()
  }

  const updateQuizQuestion = async (
    quizId: string,
    questionId: string,
    question: any,
  ): Promise<any> => {
    const res = await fetch(
      `${documentHost}/quizzes/${quizId}/questions/${questionId}`,
      {
        method: 'PATCH',
        headers: refHeaders.current,
        body: JSON.stringify(question),
      },
    )
    return res.json()
  }

  const deleteQuiz = async (quizId: string): Promise<any> => {
    const res = await fetch(`${documentHost}/quizzes/${quizId}`, {
      method: 'DELETE',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const exportQuiz = async (quizId: string): Promise<any> => {
    const res = await fetch(`${documentHost}/quizzes/${quizId}/export`, {
      method: 'GET',
      headers: refHeaders.current,
    })
    return res.blob()
  }

  const addQuizQuestionFile = async (
    quizId: string,
    questionId: string,
    url: string,
    type: string,
    name: string,
    size: number,
    version: number,
  ): Promise<QuizQuestionFile> => {
    const api = `${documentHost}/quizzes/${quizId}/questions/${questionId}/files`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        url: url,
        type: type,
        name: name,
        size: size,
        version: version,
      }),
    })
    const data = await res.json()
    if (res.status >= 400) {
      throw data
    }
    return data
  }

  const deleteQuizQuestionFile = async (
    quizId: string,
    questionId: string,
    fileId: string,
  ): Promise<BaseResponse> => {
    const api = `${documentHost}/quizzes/${quizId}/questions/${questionId}/files/${fileId}`
    const res = await fetch(api, {
      method: 'DELETE',
      headers: refHeaders.current,
    })
    return res.json()
  }

  const getQuizCreatedBy = async (): Promise<QuizCreator[]> => {
    try {
      const api = `${documentHost}/quizzes/created-by`
      const res = await fetch(api, {
        method: 'GET',
        headers: refHeaders.current,
      })
      const result = await res.json()
      if (res.status >= 400) {
        throw result
      }
      return result as QuizCreator[]
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const getQuizUpdatedBy = async (): Promise<QuizEditor[]> => {
    try {
      const api = `${documentHost}/quizzes/updated-by`
      const res = await fetch(api, {
        method: 'GET',
        headers: refHeaders.current,
      })
      const result = await res.json()
      if (res.status >= 400) {
        throw result
      }
      return result as QuizEditor[]
    } catch (err) {
      console.error(err)
      return []
    }
  }

  const batchDeleteQuizzes = async (ids: string[]): Promise<BaseResponse> => {
    const api = `${documentHost}/quizzes/delete`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ ids: ids }),
    })
    return res.json()
  }

  const batchRestoreQuizzes = async (ids: string[]): Promise<BaseResponse> => {
    const api = `${documentHost}/quizzes/restore`
    const res = await fetch(api, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({ ids: ids }),
    })
    return res.json()
  }

  const createQuiz = async (
    name: string,
    description?: string,
    auth0Id?: string,
    username?: string,
    firstName?: string,
    email?: string,
    questions?: QuizQuestion[],
    documentIds?: string[],
    courseId?: string,
  ): Promise<Quiz> => {
    const res = await fetch(`${documentHost}/quizzes`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify({
        name: name,
        description: description,
        uploadedAuth0Id: auth0Id,
        uploadedUserName: username,
        uploadedFirstName: firstName,
        uploadedEmail: email,
        questions: questions,
        documentIds: documentIds,
        courseId: courseId,
      }),
    })
    return res.json()
  }

  const addQuizQuestion = async (
    quizId: string,
    question: QuizQuestion,
  ): Promise<QuizQuestion> => {
    const res = await fetch(`${documentHost}/quizzes/${quizId}/questions`, {
      method: 'POST',
      headers: refHeaders.current,
      body: JSON.stringify(question),
    })
    return res.json()
  }

  const deleteQuizQuestion = async (
    quizId: string,
    questionId: string,
  ): Promise<BaseResponse> => {
    const res = await fetch(
      `${documentHost}/quizzes/${quizId}/questions/${questionId}`,
      {
        method: 'DELETE',
        headers: refHeaders.current,
      },
    )
    return res.json()
  }

  const resendEmailToReviewer = async (
    quizId: string,
    reviewerId: string,
  ): Promise<BaseResponse> => {
    const res = await fetch(
      `${documentHost}/quizzes/${quizId}/reviewers/${reviewerId}`,
      {
        method: 'POST',
        headers: refHeaders.current,
      },
    )
    return res.json()
  }

  const providerValue = {
    setToken,
    generateQuestions,
    generateMultipleQuestionsStart,
    generateMultipleQuestionsV2,
    generateMultipleQuestionsStatus,
    getQuestionJob,
    getAllQuestionJobs,
    saveQuestionJob,
    generateNewQuestions,
    generateNewQuestionsV2,
    regenerateQuestion,
    getQuestions,
    updateQuestion,
    uploadFileForQuiz,
    addQuizReviewers,
    getQuizzes,
    getQuiz,
    updateQuiz,
    approveQuiz,
    updateQuizQuestion,
    deleteQuiz,
    exportQuiz,
    addQuizQuestionFile,
    deleteQuizQuestionFile,
    batchDeleteQuizzes,
    batchRestoreQuizzes,
    getQuizCreatedBy,
    getQuizUpdatedBy,
    createQuiz,
    addQuizQuestion,
    deleteQuizQuestion,
    resendEmailToReviewer,
  }

  return (
    <QuizApiContext.Provider value={providerValue}>
      {children}
    </QuizApiContext.Provider>
  )
}
